import React from 'react'
import { Link } from 'react-router-dom'
import { URL_PATHS } from '../../../_constants/urls'
import { scrollToTop } from '../../../_helpers'
import { useDashboardTab } from '../../../context/DashboardTabContext'
import { useAuth } from '../../../context/AuthContext'

const TemporaryBanner = () => {
  const { auth, setAuth } = useAuth()
  const { activeTab, setActiveTab } = useDashboardTab()
  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })
  return (
    <>
      <div className="testimonial-section pt-80 pb-80">
        <img
          alt="testimonialImage"
          src={process.env.PUBLIC_URL + '/images/bg/client-right.png'}
          className="client-right-vector"
        />
        <img
          alt="testimonialImage"
          src={process.env.PUBLIC_URL + '/images/bg/client-left.png'}
          className="client-left-vector"
        />
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-sm-12 col-md-10 col-lg-8 col-xl-8">
              <div className="section-title1">
                <h2 style={{ marginTop: '30px', marginBottom: '30px' }}>mamfuche.pl wystartowało! 🚀</h2>
                <p
                  style={{ marginTop: '-20px', marginLeft: 'auto', marginRight: 'auto', maxWidth: '500px' }}
                  className="para"
                ></p>
                <p className="para">
                  <b>
                    Nasza platforma właśnie ruszyła! Teraz możesz bezpłatnie wystawiać swoje fuchy (ogłoszenia) i
                    znaleźć sprawdzonych fachowców w Twojej okolicy! 🛠️
                  </b>
                </p>
                <h3 className="mt-5">Dlaczego warto dołączyć?</h3>
                <ul style={{ padding: '0' }}>
                  <li>🚀 Darmowe dodawanie ogłoszeń!</li>
                  <li>🔎 Szybkie znalezienie fachowca do Twojej fuchy!</li>
                  <li>⭐ Sprawdzona społeczność ekspertów!</li>
                  <li>💬 Darmowy chat z fachowcami – zapytaj o szczegóły przed podjęciem decyzji!</li>
                  <li>📌 Zbieraj darmowe wyceny od wielu fachowców i wybierz najlepszą ofertę!</li>
                </ul>
                <p style={{ marginTop: '30px', marginBottom: '30px' }}>
                  <b>Nie zwlekaj – wystaw swoją pierwszą fuchę i skorzystaj z pełnych możliwości platformy!</b>
                </p>

                {auth?.user.is_contractor ? null : (
                  <>
                    <h3>Wystaw fuchę za darmo teraz!</h3>
                    <div className="eg-btn btn--primary header-btn m-3">
                      <Link
                        to={`${URL_PATHS.DASHBOARD}`}
                        onClick={() => {
                          setActiveTab('tab21')
                          scrollTop()
                        }}
                      >
                        Wystaw Fuchę
                      </Link>
                    </div>
                  </>
                )}
                <h3 className="mt-5">Jesteś fachowcem?</h3>
                <p className="para">
                  Wypróbuj jak działa mamfuche.pl! Teraz dostajesz od nas <b>12 miesięcy konta za darmo!</b> 🎉 Masz
                  pełen dostęp do zgłoszeń i możesz zdobywać nowych klientów bez żadnych opłat!
                </p>
                {auth?.user.is_contractor ? null : (
                  <>
                    <div className="eg-btn btn--primary header-btn m-3">
                      <Link
                        to={URL_PATHS.SIGNUP_CONTRACTOR}
                        onClick={() => {
                          scrollTop()
                        }}
                      >
                        Dołącz Jako Fachowiec
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TemporaryBanner
