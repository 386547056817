import React from 'react'
import { Link } from 'react-router-dom'
import { URL_PATHS } from '../../../../_constants/urls'
import { scrollToTop } from '../../../../_helpers'
import { useUserActions } from '../../../../_actions'

const RegisterContractorAccountCard = () => {
  const { logout } = useUserActions()
  return (
    <div className="d-flex align-items-stretch">
      <div className="card side-card mb-3">
        <div className="card-body">
          <div className="sidebar-widget-title">
            <h4>Chcesz złożyć wycenę?</h4>
            <span />
          </div>
          <div className="details-container">
            <div className="product-details-right  wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
              <div className="form-title no-signed-view-title">
                <h5>Załóż konto i wyślij swoją wycenę!</h5>
                <p className="para">
                  Na <span className="logo-paragraph ">mamfuche.pl</span> nie mamy żadych ukrytych kosztów! Zarejestruj
                  się i zacznij swoją pierwszą Fuchę!
                </p>
              </div>
              <div className="eg-btn btn--primary p-2 join-btn">
                <Link
                  to={URL_PATHS.SIGNUP_CONTRACTOR}
                  onClick={() => {
                    scrollToTop()
                    logout()
                  }}
                >
                  Dołącz jako fachowiec
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegisterContractorAccountCard
