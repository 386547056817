import React from 'react'

const Input = ({
  register,
  counter,
  name,
  error,
  label,
  maxLength,
  onInput,
  onChange,
  wrapperClass,
  containerClassName,
  icon,
  ...rest
}) => {
  return (
    <div className={containerClassName}>
      <div className="form-inner">
        {label && <label htmlFor={name}>{label}</label>}
        <input
          {...register(name)}
          onChange={onChange}
          onInput={onInput}
          aria-invalid={error ? 'true' : 'false'}
          maxLength={maxLength}
          {...rest}
        />
        {icon}
        {error && <div className="error-message">{error}</div>}
        {counter && <div className="title-text-counter">{counter}</div>}
      </div>
    </div>
  )
}

export default Input
