import { React, useState, Fragment, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useDashboardTab } from '../../../context/DashboardTabContext'
import { history } from '../../../_helpers'
import TabNavItem from './TabNavItem'
import TabContent from './TabContent'
import ContentOfDashboardTab from './ContentOfDashboardTab'
import ContentOfMyAccount from './ContentOfMyAccount'
import ContentOfMyProfile from './ContentOfMyProfile'
import ContentOfPayments from './ContentOfPayments'
import ContentOfMyFuchy from './ContentOfMyFuchy'
import ContentOfPublishFuche from './ContentOfPublishFuche'
import ContentOfNotifications from './ContentOfNotifications'
import ContentOfMyReviews from './ContentOfMyReviews'
import SkeletonSummaryTiles from './SkeleteonContent'
import ContentOfMyFuchyClient from './ContentOfMyFuchyClient'
import { useIsLargeScreen } from '../../../_helpers/hooks'

export const tabMapping = {
  // Common
  tab01: 'panel',
  tab03: 'konto',
  tab04: 'powiadomienia',
  tab05: 'wiadomosci',
  // Contractor
  tab11: 'profil',
  tab12: 'fuchy',
  tab13: 'platnosci',
  // Client
  tab21: 'wystaw-fuche',
  tab22: 'moje-fuchy',
  tab15: 'moje-recenzje',
}

const Tabs = ({ user, loadingUser }) => {
  const [showCreateProfileModal, setShowCreateProfileModal] = useState(false)
  const { activeTab, setActiveTab } = useDashboardTab('tab01')

  const handleShowCreateProfileModal = () => {
    setShowCreateProfileModal(true)
  }

  const handleCloseCreateProfileModal = () => {
    setShowCreateProfileModal(false)
  }

  const changeTab = (tab) => {
    setActiveTab(tab)
  }

  // this fix the issue when client leaving dashboard with active 'wystaw fuchę' button
  // and then clicking the 'Mój Profil' button in the header which would always open the 'Wystaw Fuchę' form
  // it should be working only on big screens because on small this logic isn't needed
  const isLargeScreen = useIsLargeScreen()

  console.log(isLargeScreen && activeTab === 'tab21')

  useEffect(() => {
    if (isLargeScreen && activeTab && activeTab === 'tab21') {
      // Reset activeTab to null when leaving the page
      const unlisten = history.listen(() => {
        setActiveTab(null)
      })

      return () => {
        unlisten() // Cleanup listener on unmount
      }
    }
  }, [isLargeScreen, activeTab, history, setActiveTab])

  useEffect(() => {
    if (isLargeScreen && !activeTab) {
      // Set 'tab01' as the default active tab when the page loads
      setActiveTab('tab01')
    }
  }, [isLargeScreen, activeTab, setActiveTab])

  // Contractor tabs starts with 1 and client tabs starts with 2, 0 are common
  const renderContractorNavItems = () => {
    return (
      <Fragment>
        <TabNavItem title="Panel Główny" id="tab01" activeTab={activeTab} setActiveTab={changeTab} />
        <TabNavItem title="Profil Fachowca" id="tab11" activeTab={activeTab} setActiveTab={changeTab} />
        <TabNavItem title="Moje Konto" id="tab03" activeTab={activeTab} setActiveTab={changeTab} />
        <TabNavItem title="Moje Fuchy" id="tab12" activeTab={activeTab} setActiveTab={changeTab} />
        <TabNavItem title="Płatności" id="tab13" activeTab={activeTab} setActiveTab={changeTab} />
        <TabNavItem title="Powiadomienia" id="tab04" activeTab={activeTab} setActiveTab={changeTab} />
        <TabNavItem title="Wiadomości" id="tab05" activeTab={activeTab} setActiveTab={changeTab} />
        <TabNavItem title="Wyloguj się" id="tab02" activeTab={activeTab} setActiveTab={changeTab} />
      </Fragment>
    )
  }

  const renderClientNavItems = () => {
    return (
      <Fragment>
        <TabNavItem title="Panel Główny" id="tab01" activeTab={activeTab} setActiveTab={changeTab} />
        <TabNavItem title="Wystaw Fuchę" id="tab21" activeTab={activeTab} setActiveTab={changeTab} />
        <TabNavItem title="Moje Fuchy" id="tab22" activeTab={activeTab} setActiveTab={changeTab} />
        <TabNavItem title="Moje Konto" id="tab03" activeTab={activeTab} setActiveTab={changeTab} />
        <TabNavItem title="Powiadomienia" id="tab04" activeTab={activeTab} setActiveTab={changeTab} />
        <TabNavItem title="Wiadomości" id="tab05" activeTab={activeTab} setActiveTab={changeTab} />
        <TabNavItem title="Wyloguj się" id="tab02" activeTab={activeTab} setActiveTab={changeTab} />
      </Fragment>
    )
  }

  const skeletonNavItems = () => {
    return Array.from({ length: 6 }).map((_, index) => (
      <Skeleton height={50} key={index} className={'nav-link nav-btn-style mx-auto mb-20'} />
    ))
  }

  const renderCreateFuchaSkeleton = () => {
    return <Skeleton height={500} />
  }

  const renderCreateContractorProfileSkeleton = () => {
    return <Skeleton height={500} />
  }

  const renderMyAccountSkeleton = () => {
    return <Skeleton height={1000} />
  }

  const renderNotificationsSkeleton = () => {
    return Array.from({ length: 4 }).map((_, index) => <Skeleton height={100} key={index} className={'mb-20'} />)
  }

  return (
    <div className="dashboard-section pt-120 pb-120">
      <img
        alt="images"
        src={process.env.PUBLIC_URL + '/images/bg/client-right.png'}
        className="img-fluid section-bg-1"
      />
      <img
        alt="images"
        src={process.env.PUBLIC_URL + '/images/bg/client-left.png'}
        className="img-fluid section-bg-2"
      />
      <div className="container">
        <div className="row g-4">
          <div className="col-lg-3">
            <div className="dashboard-menu nav flex-column nav-pills gap-4 wow fadeInUp">
              {loadingUser
                ? skeletonNavItems()
                : user.is_contractor
                  ? renderContractorNavItems()
                  : renderClientNavItems()}
            </div>
          </div>

          <div className="col-lg-9 col-md-12 col-sm-12">
            <div className="tab-content">
              {loadingUser ? (
                <>
                  <TabContent id="tab01" activeTab={activeTab}>
                    {SkeletonSummaryTiles()}
                  </TabContent>
                  <TabContent id="tab03" activeTab={activeTab}>
                    {renderMyAccountSkeleton()}
                  </TabContent>
                  <TabContent id="tab04" activeTab={activeTab}>
                    {renderNotificationsSkeleton()}
                  </TabContent>
                  <TabContent id="tab11" activeTab={activeTab}>
                    {renderCreateContractorProfileSkeleton()}
                  </TabContent>
                  <TabContent id="tab21" activeTab={activeTab}>
                    {renderCreateFuchaSkeleton()}
                  </TabContent>
                </>
              ) : (
                <>
                  <TabContent id="tab01" activeTab={activeTab}>
                    <ContentOfDashboardTab user={user} />
                  </TabContent>
                  <TabContent id="tab03" activeTab={activeTab}>
                    <ContentOfMyAccount user={user} />
                  </TabContent>
                  <TabContent id="tab04" activeTab={activeTab}>
                    <ContentOfNotifications user={user} />
                  </TabContent>
                  <TabContent className="create-profile-tab-content" id="tab11" activeTab={activeTab}>
                    <ContentOfMyProfile
                      showCreateProfileModal={showCreateProfileModal}
                      handleShowCreateProfileModal={handleShowCreateProfileModal}
                      handleCloseCreateProfileModal={handleCloseCreateProfileModal}
                      user={user}
                    />
                  </TabContent>
                  <TabContent id="tab12" activeTab={activeTab}>
                    <ContentOfMyFuchy user={user} />
                  </TabContent>
                  <TabContent id="tab13" activeTab={activeTab}>
                    <ContentOfPayments user={user} />
                  </TabContent>
                  <TabContent id="tab15" activeTab={activeTab}>
                    <ContentOfMyReviews />
                  </TabContent>
                  <TabContent id="tab21" activeTab={activeTab}>
                    <ContentOfPublishFuche />
                  </TabContent>
                  <TabContent id="tab22" activeTab={activeTab}>
                    <ContentOfMyFuchyClient user={user} />
                  </TabContent>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tabs
