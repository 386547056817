export const GOOGLE_EVENT_NAME = Object.freeze({
  ADD_PAYMENT_INFO: 'add_payment_info',
  ADD_TO_CART: 'add_to_cart',
  ADD_TO_WISHLIST: 'add_to_wishlist',
  BEGIN_CHECKOUT: 'begin_checkout',
  COMPLETE_REGISTRATION: 'sign_up',
  CONTACT: 'contact',
  PURCHASE: 'purchase',
  GENERATE_LEAD: 'generate_lead',
  PAGE_VIEW: 'page_view',
  SEARCH: 'search',
  VIEW_ITEM: 'view_item',
  VIEW_ITEM_LIST: 'view_item_list',
  START_TRIAL: 'start_trial',
  SUBSCRIBE: 'subscribe',
  CUSTOM_EVENT: 'custom_event',
})

export const googleAdsEvent = (eventName, eventParams = {}, eventId = null) => {
  try {
    const gtag = window.gtag
    if (!gtag) {
      throw new Error('Google Ads gtag is not defined')
    }

    if (Object.values(GOOGLE_EVENT_NAME).includes(eventName)) {
      gtag('event', eventName, {
        ...eventParams,
        send_to: eventId || undefined, // Add event ID if provided
      })
    } else {
      // Handle custom events if needed
      gtag('event', GOOGLE_EVENT_NAME.CUSTOM_EVENT, {
        event_category: 'Custom',
        event_action: eventName,
        ...eventParams,
        send_to: eventId || undefined,
      })
    }
  } catch (error) {
    console.error('Google Ads Event Error:', error)
  }
}

export const isGoogleAdsUser = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const gclid = urlParams.get('gclid')
  const utmSource = urlParams.get('utm_source')

  // Google Ads detection via gclid or utm_source
  return gclid !== null || utmSource === 'google'
}
