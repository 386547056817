import React from 'react'
import Breadcrumb from '../../common/Breadcrumb'
import SimpleBox from '../../common/SimpleBox'
import { Link, NavLink } from 'react-router-dom'
import { URL_PATHS } from '../../../_constants'

const PricesPage = () => {
  const plans = [
    {
      plan: 'Półroczny Plan',
      price: 99,
      duration: '6 miesięcy',
    },
    {
      plan: 'Roczny Plan',
      price: 179,
      duration: '12 miesięcy',
    },
  ]

  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })

  return (
    <div>
      <Breadcrumb
        pageName="Cennik - Dla Fachowców"
        pageTitle="W naszym serwisie nie używamy tokenów. Zapłać raz i korzystaj ile chcesz!"
      />
      <div className="w-100 py-5 bg-light pricing-section">
        <div className="container">
          <img
            alt="testimonialImage"
            src={process.env.PUBLIC_URL + '/images/bg/client-left.png'}
            className="client-left-vector"
          />
          <img
            alt="testimonialImage"
            src={process.env.PUBLIC_URL + '/images/bg/client-right.png'}
            className="client-right-vector"
          />
          <div className="col-md-12">
            <div className="row justify-content-center">
              <div className="col-md-8 text-center">
                <h2 className="mb-4 mt-5">Zapomnij o tokenach!</h2>
                <h5 className="subtitle font-weight-normal mb-2">
                  Po opłaceniu jednego z planów możesz w pełni korzystać z naszej platformy bez ukrytych kosztów!
                </h5>
                <h3 className="text-success mt-4">Teraz każdy otrzymuje 12 miesięcy darmowego konta!</h3>
              </div>
            </div>
            <div className="row justify-content-center mt-5 mb-5">
              {plans.map(({ plan, price, duration }, index) => (
                <div className="col-md-3" key={index}>
                  <div className="card card-shadow border-0 mb-4">
                    <div className="card-body text-center">
                      <h5 className="font-weight-medium mb-0">{plan}</h5>
                      <div className="my-3">
                        <span className="text-dark display-3" style={{ textDecoration: 'line-through' }}>
                          {price}
                        </span>
                        <sup>PLN</sup>
                        <h5 style={{ marginTop: '18px' }} className="font-weight-light">
                          {duration}
                        </h5>
                      </div>
                      <h4 className="text-success">Teraz za darmo!</h4>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="row justify-content-center mt-5 mb-5">
              <div className="col-md-8 text-center">
                <h2 className="mb-2">W tej cenie otrzymujesz od nas:</h2>
              </div>
            </div>
            <div className="row d-flex justify-content-center g-4 mt-4 mb-5">
              <SimpleBox
                title="Dostęp do fuch w całej Polsce"
                text="Możliwość przeglądania fuch z różnych regionów daje większe szanse na znalezienie idealnych zleceń."
              />
              <SimpleBox
                title="Więcej szans na zlecenia"
                text="Możesz wysyłać wyceny nawet do 20 fuch jednocześnie i składać aż do 2 wycen na każdą fuchę!"
              />
              <SimpleBox
                title="Jasne zasady"
                text="Proste i przejrzyste warunki, dzięki którym wiesz, czego się spodziewać na każdym etapie współpracy."
              />
              <SimpleBox
                title="Jedna opłata"
                text="Korzystaj ze wszystkich dostępnych narzędzi bez żadnych dodatkowych opłat i ciesz się pełną swobodą działania!"
              />
              <SimpleBox
                title="Dostęp do czatu"
                text="Skontaktuj się bezpośrednio z klientem aby otrzymać jak najwięcej szczegółów dotyczących wystawianej fuchy!"
              />
              <SimpleBox
                title="Profil fachowca"
                text="Zbuduj profesjonalny profil fachowca. Dzięki temu będziesz miał zdecydowanie większą szansę wygrać więcej fuch!"
              />
            </div>
            <div className="row justify-content-center">
              <div className="col-md-8 text-center">
                <h4 className="mb-4 mt-4 prices-header">
                  Aby aktywować konto i rozpocząć szukanie fuch, przejdź do swojego profilu, wybierz zakładkę
                  "Płatności" i wybierz jedną z dostępnych opcji. <br /> <br /> Jeśli nie masz jeszcze konta,
                  zarejestruj się i rozpocznij swoją przygodę z <span>mamfuche.pl</span>!
                </h4>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-8 text-center">
                <div className="eg-btn btn--primary header-btn mt-2 mb-5">
                  <Link to={URL_PATHS.SIGNUP_CONTRACTOR} onClick={scrollTop}>
                    Dołącz jako fachowiec
                  </Link>
                </div>
              </div>
            </div>
            <img
              alt="testimonialImage"
              src={process.env.PUBLIC_URL + '/images/bg/client-left.png'}
              className="client-circle4"
            />
            <img
              alt="testimonialImage"
              src={process.env.PUBLIC_URL + '/images/bg/client-right.png'}
              className="client-circle5"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PricesPage
