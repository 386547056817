import React, { useEffect, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, Controller } from 'react-hook-form'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Checkbox from '@mui/material/Checkbox'
import { useFuchaActions, useContractorActions } from '../../../../_actions/'
import { SendBidSchema } from '../schema'
import { useAlerts } from '../../../../context/AlertContext'
import ConfirmModal from '../../../common/ConfirmModal'
import { BID_RATE_TYPE } from '../../../../_constants/values'

const PlaceBidCard = ({ fucha, bids, setBids, loadingBids, onBidAdded }) => {
  const { addAlert } = useAlerts()
  const { placeBid } = useFuchaActions()
  const { getMyBids } = useContractorActions()
  const [showModal, setShowModal] = useState(false)

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: zodResolver(SendBidSchema),
    defaultValues: { bidRateType: BID_RATE_TYPE.job, needMoreInfo: false },
  })

  const handleSubmitBid = (values) => {
    const payload = {
      fucha_id: fucha.id,
      bid: values.bid,
      message: values.message,
      rate_type: watch('bidRateType'),
      need_more_info: watch('needMoreInfo'),
    }
    placeBid(payload)
      .then((data) => {
        setBids((prev) => {
          const updatedBids = prev.map((bid) => ({ ...bid, is_deleted: true }))
          return [{ ...data, is_deleted: false }, ...updatedBids]
        })
        reset()
        addAlert('Twoja wycena została wysłana, a zleceniodawca zostanie niedługo poinformowany.', 'success')
        onBidAdded()
      })
      .catch((err) => {
        if (err?.response?.data?.display) {
          addAlert(err.response.data.detail, 'error')
        } else {
          addAlert('Nie udało się wysłać wyceny. Spróbuj ponownie później...', 'error')
        }
      })
      .finally(() => {
        setShowModal(false)
      })
  }

  const renderConfirmationModal = () => {
    const amount = watch('bid')
    const rateType = watch('bidRateType')
    return (
      <ConfirmModal
        header="Potwierdzenie wyceny"
        showModal={showModal}
        handleClose={handleCloseModal}
        handleConfirm={handleSubmit(handleSubmitBid)}
        size="lg"
        className="confirm-bid-modal"
      >
        <p className="para">
          Twoja wycena to{' '}
          <b>
            {amount} zł
            {rateType === BID_RATE_TYPE.hourly
              ? ' /h'
              : rateType === BID_RATE_TYPE.meter
                ? ' /mb'
                : rateType === BID_RATE_TYPE.sqm
                  ? ' /m2'
                  : ''}
          </b>
          . Czy na pewno chcesz wysłać tę wycenę?
        </p>
      </ConfirmModal>
    )
  }

  return (
    <>
      <div className="card mb-3" style={{ marginRight: '0' }}>
        <div className="card-body">
          <div style={{ position: 'relative' }} className="sidebar-widget-title">
            <h4>Wyślij wycenę</h4>
            <span />
            <OverlayTrigger
              key="place-bid-tooltip"
              placement="top"
              overlay={
                <Tooltip id="place-bid-tooltip">
                  Możesz składać wyceny na maksymalnie 20 fuch jednocześnie. Liczba ta zmniejsza się tylko wtedy, gdy
                  wycofasz wycenę lub fucha zostanie zakończona. Aktualną liczbę znajdziesz na swoim profilu.
                </Tooltip>
              }
            >
              <i
                style={{ position: 'absolute', top: '0', right: '0', cursor: 'default' }}
                className="bi bi-info-circle"
              ></i>
            </OverlayTrigger>
          </div>
          <div className="product-details-right  wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
            {loadingBids ? (
              <div className="bid-form">
                <Skeleton height={200} />
              </div>
            ) : (
              <div className="bid-form">
                {bids.length < 2 ? (
                  <>
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="form-inner gap-2">
                        <input
                          {...register('bid')}
                          id="bid"
                          name="bid"
                          type="text"
                          onKeyDown={(event) => {
                            const allowedKeys = ['Enter', 'Backspace', 'Escape']
                            if (!/[0-9]/.test(event.key) && !allowedKeys.includes(event.key)) {
                              event.preventDefault()
                            }
                          }}
                          placeholder="0 zł"
                          required
                          maxLength={7}
                        />
                        {errors.bid && <div className="error-message">{errors.bid.message}</div>}
                        <FormControl component="fieldset">
                          <FormLabel>Kwota:</FormLabel>
                          <Controller
                            rules={{ required: true }}
                            control={control}
                            name="bidRateType"
                            render={({ field }) => (
                              <RadioGroup row {...field}>
                                <FormControlLabel
                                  value={BID_RATE_TYPE.job}
                                  control={
                                    <Radio
                                      sx={{
                                        '&.Mui-checked': {
                                          color: '#f9395f',
                                        },
                                      }}
                                    />
                                  }
                                  label="całkowita"
                                />
                                <FormControlLabel
                                  value={BID_RATE_TYPE.hourly}
                                  control={
                                    <Radio
                                      sx={{
                                        '&.Mui-checked': {
                                          color: '#f9395f',
                                        },
                                      }}
                                    />
                                  }
                                  label="/h"
                                />
                                <FormControlLabel
                                  value={BID_RATE_TYPE.meter}
                                  control={
                                    <Radio
                                      sx={{
                                        '&.Mui-checked': {
                                          color: '#f9395f',
                                        },
                                      }}
                                    />
                                  }
                                  label="/mb"
                                />
                                <FormControlLabel
                                  value={BID_RATE_TYPE.sqm}
                                  control={
                                    <Radio
                                      sx={{
                                        '&.Mui-checked': {
                                          color: '#f9395f',
                                        },
                                      }}
                                    />
                                  }
                                  label="/m2"
                                />
                              </RadioGroup>
                            )}
                          />
                        </FormControl>
                        <FormControl className="need-more-info">
                          <Controller
                            rules={{ required: false }}
                            control={control}
                            name="needMoreInfo"
                            defaultValue={false}
                            render={({ field }) => (
                              <Checkbox
                                {...field}
                                checked={field['value'] ?? false}
                                sx={{
                                  '&.Mui-checked': {
                                    color: '#f9395f',
                                  },
                                }}
                              />
                            )}
                          />
                          <FormLabel>
                            {' '}
                            Zaznacz to pole jeżeli potrzebujesz więcej informacji do dokładniejszej wyceny
                          </FormLabel>
                        </FormControl>
                      </div>
                      <button
                        onClick={() => {
                          const amount = watch('bid')
                          if (amount && amount > 0) {
                            setShowModal(true)
                          } else {
                            setShowModal(false)
                          }
                        }}
                        className="eg-btn btn--primary btn--sm"
                      >
                        Wyślij wycenę
                      </button>
                    </form>
                    <div className="extra-info-container">
                      <p className="bid-para">
                        Liczba wystawionych wycen: <b>{bids.length}/2</b>
                      </p>
                      {bids.length === 1 && (
                        <p className="bid-para">
                          Twoja poprzednia wycena to:{' '}
                          <b>
                            {bids[0].bid} zł
                            {bids[0].rate_type === BID_RATE_TYPE.hourly
                              ? ' /h'
                              : bids[0].rate_type === BID_RATE_TYPE.meter
                                ? ' /mb'
                                : bids[0].rate_type === BID_RATE_TYPE.sqm
                                  ? ' /m2'
                                  : ''}
                          </b>
                        </p>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="extra-info-container">
                    <p className="para">Niestety złożyłeś już maksymalną liczbę wycen.</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {showModal && renderConfirmationModal()}
    </>
  )
}

export default PlaceBidCard
