import React, { useState, useEffect } from 'react'

import { useApi } from '../../../../../_helpers'
import { useUser } from '../../../../../context/UserContext'
import { useContractorActions } from '../../../../../_actions'
import UploadGallery from '../../../../../components/common/UploadGallery'
import { MAX_IMAGE_SIZE_MB, MAX_GALLERY_IMAGES, DYNAMIC_API_URLS } from '../../../../../_constants'

const PageFive = () => {
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(true)

  const { api } = useApi()
  const { user } = useUser()
  const { getGallery, uploadGalleryImage } = useContractorActions()

  const EXPLANATION_MESSAGE = (
    <p>
      Przeciągnij lub dodaj zdjęcia z wykonanych prac.
      <br></br> Pamiętaj, że pierwsze załadowane zdjęcie będzie używane jako miniatura dla ogłoszenia.
    </p>
  )

  useEffect(() => {
    getGallery(user.id)
      .then((resp) => {
        const initUserGallery = resp.map((item) => {
          return {
            source: `${item.url}`,
            options: {
              type: 'local',
              serverId: item.id,
            },
            serverId: item.id,
          }
        })
        setFiles(initUserGallery)

        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }, [])

  const fileUploadAPIHandler = async (file, fieldName, progress, sourceToken) => {
    return await uploadGalleryImage(file, fieldName, progress, sourceToken)
  }

  const fileDeleteAPIHandler = async (fileId) => {
    api.delete(DYNAMIC_API_URLS.ContractorGalleryDetail(fileId), {}).catch((err) => {
      console.error('Error removing file:', err)
      if (err.response && err.response.status === 404) {
        console.warn('File not found on server. It may have already been removed.')
      }
    })
  }

  return (
    <UploadGallery
      loading={loading}
      initialImages={files}
      maxFilesQty={MAX_GALLERY_IMAGES}
      maxImageSizeMB={MAX_IMAGE_SIZE_MB}
      fileUploadAPIHandler={fileUploadAPIHandler}
      fileDeleteAPIHandler={fileDeleteAPIHandler}
      explanationText={EXPLANATION_MESSAGE}
    />
  )
}

export default PageFive
