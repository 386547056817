import React from 'react'
import { Link } from 'react-router-dom'
import { URL_PATHS } from '../../../_constants/urls'

const HowItWorkContent = () => {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })
  return (
    <>
      <div className="how-work-section pt-120 pb-120">
        <img alt="images" src={process.env.PUBLIC_URL + '/images/bg/section-bg2.png'} className="section-bg-top" />
        <div className="container">
          <div className="row g-4 mb-60">
            <div className="how-work-title">
              <h3>
                Potrzebujesz wykonawcy do prac budowlanych, naprawy auta lub projektu wnętrza, ogrodu czy mebli?
                Opublikuj zlecenie na mamfuche.pl i otrzymaj wyceny od sprawdzonych fachowców!
              </h3>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="how-work-content wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s">
                <span>01.</span>
                <h3>Rejestracja i zakładanie konta</h3>
                <p className="para">
                  Jeżeli nie masz zarejestrowanego konta, kliknij przycisk poniżej. Wypełnij formularz rejestracyjny,
                  podając swoje podstawowe dane, takie jak imię, adres e-mail oraz hasło. Po zakończeniu rejestracji
                  otrzymasz e-mail z potwierdzeniem, który umożliwi aktywację Twojego konta. Sprawdź swoj email i
                  aktywuj konto.
                </p>
                <Link to={URL_PATHS.SIGNUP} onClick={scrollTop} className="eg-btn btn--primary btn--md">
                  Załóż konto
                </Link>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 d-flex justify-content-lg-end justify-content-center">
              <div className="how-work-img wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
                <img alt="images" src={process.env.PUBLIC_URL + '/images/bg/login.svg'} className="work-img" />
              </div>
            </div>
          </div>
          <div className="row g-4 mb-60">
            <div className="col-xl-6 col-lg-6 d-flex justify-content-lg-start justify-content-center order-lg-1 order-2">
              <div className="how-work-img wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
                <img alt="images" src={process.env.PUBLIC_URL + '/images/bg/search.svg'} className="work-img" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 order-lg-2 order-1">
              <div className="how-work-content wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s">
                <span>02.</span>
                <h3>Wystawianie fuchy i wybór fachowca</h3>
                <p className="para">
                  Po zalogowaniu, dodaj fuchę. Opisz dokładnie, czego potrzebujesz, aby fachowcy mogli składać trafne
                  wyceny. Następnie przeglądaj otrzymane wyceny, porównuj je oraz recenzje fachowców. Wybierz wycenę,
                  która najlepiej odpowiada Twoim potrzebom i budżetowi. Wystawianie fuch na naszym serwisie jest w
                  pełni darmowe!
                </p>
                <p className="para">
                  Uwaga! Twoje dane kontaktowe nie są udostępniane publicznie! Możesz kontaktować się z fachowcami
                  poprzez nasz czat. Dopiero kiedy zaakceptujesz wycenę, Twoje dane kontaktowe bedą przekazane wybranemu
                  fachowcowi.
                </p>
                <Link to={URL_PATHS.SIGNUP} onClick={scrollTop} className="eg-btn btn--primary btn--md">
                  Wystaw Fuchę
                </Link>
              </div>
            </div>
          </div>
          <div className="row g-4">
            <div className="col-xl-6 col-lg-6">
              <div className="how-work-content wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s">
                <span>03.</span>
                <h3>Wystawianie recenzji</h3>
                <p className="para">
                  Po zakończeniu prac, oceń fachowca. Wystawienie recenzji pomoże innym użytkownikom w wyborze fachowców
                  oraz pozwoli serwisowi mamfuche.pl utrzymać wysoką jakość oferowanych usług.
                </p>
                <p className="para">Twoja opinia jest ważna i pomoże budować zaufanie w społeczności mamfuche.pl!</p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 d-flex justify-content-lg-end justify-content-center">
              <div className="how-work-img wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
                <img alt="images" src={process.env.PUBLIC_URL + '/images/bg/reviews.svg'} className="work-img" />
              </div>
            </div>
            {/* Add YouTube Video Section */}
            <div className="row g-4 video-container">
              <div className="col-12">
                <div className="video-box">
                  <iframe
                    width="100%"
                    height="600"
                    src="https://www.youtube.com/embed/IBziDa4b62Y"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HowItWorkContent
