import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './components/App'

import { PostHogProvider } from 'posthog-js/react'

const ALLOWED_ENVS = ['production', 'staging']

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  opt_out_capturing_by_default: !ALLOWED_ENVS.includes(process.env.REACT_APP_ENVIRONMENT),
  disable_session_recording: !ALLOWED_ENVS.includes(process.env.REACT_APP_ENVIRONMENT),
}

ReactDOM.render(
  <React.StrictMode>
    <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
      <App />
    </PostHogProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
