import React from 'react'
import { Link } from 'react-router-dom'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { DYNAMIC_URL_PATHS } from '../../../../_constants'
import { scrollToTop } from '../../../../_helpers'
import AccountIcon from '../../../../assets/images/icons/account.svg'
import { renderStarRating } from '../../../../_helpers'
import { BID_RATE_TYPE } from '../../../../_constants/values'

const SmallScreenBidItem = (props) => {
  const {
    acceptedBid,
    bid,
    disabledFuchaAction,
    fucha,
    handleChatStart,
    handleGetContractorReviewsSummary,
    handleOpenContractorReviewsSummaryModal,
    isOwner,
    setBidIdToDelete,
    setBidToAccept,
    setShowModal,
    setShowReviewModal,
  } = props
  const contractor = bid.contractor
  const isAccepted = bid.id === acceptedBid?.id
  return (
    <>
      <div key={`${bid.id}-small`} className="offer-list-item-container d-lg-none d-flex">
        <div className="bid-contractor-details">
          <div className="offer-image-container">
            <div className="offer-image-box">
              <Link to={DYNAMIC_URL_PATHS.ContractorProfile(bid.contractor.id)} onClick={() => scrollToTop()}>
                <OverlayTrigger
                  key={`contractor-small-img-${bid.id}`}
                  placement="top"
                  overlay={
                    <Tooltip id={`contractor-small-img-${bid.id}`}>Kliknij żeby przejść do profilu fachowca</Tooltip>
                  }
                >
                  <img
                    id={`contractor-small-img-${bid.id}`}
                    src={contractor.image ? contractor.image : AccountIcon}
                    alt="Admin"
                    className="rounded-circle"
                    width="50"
                  />
                </OverlayTrigger>
              </Link>
            </div>
          </div>

          <div className="bid-contractor-info">
            {contractor.enable_display_company_name && contractor.company_name ? (
              <div style={{ maxWidth: '70%' }} className="offer-list-item">
                <Link to={DYNAMIC_URL_PATHS.ContractorProfile(bid.contractor.id)} onClick={() => scrollToTop()}>
                  <OverlayTrigger
                    key={`contractor-small-name-${bid.id}`}
                    placement="top"
                    overlay={
                      <Tooltip id={`contractor-small-name-${bid.id}`}>Kliknij żeby przejść do profilu fachowca</Tooltip>
                    }
                  >
                    <p style={{ textAlign: 'left' }} id={`contractor-small-name-${bid.id}`} className="para name">
                      {contractor.company_name}
                    </p>
                  </OverlayTrigger>
                </Link>
              </div>
            ) : (
              <div className="offer-list-item">
                <Link to={DYNAMIC_URL_PATHS.ContractorProfile(bid.contractor.id)} onClick={() => scrollToTop()}>
                  <OverlayTrigger
                    key={`contractor-small-name-${bid.id}`}
                    placement="top"
                    overlay={
                      <Tooltip id={`contractor-small-name-${bid.id}`}>Kliknij żeby przejść do profilu fachowca</Tooltip>
                    }
                  >
                    <p id={`contractor-small-name-${bid.id}`} className="para name">
                      {contractor.name}
                    </p>
                  </OverlayTrigger>
                </Link>
              </div>
            )}

            <div className="offer-list-item">
              <div
                style={{ cursor: bid.contractor.average_score > 0 ? 'pointer' : 'auto' }}
                className="offer-review-rate"
                onClick={() => {
                  if (bid.contractor.average_score > 0) {
                    handleOpenContractorReviewsSummaryModal(bid)
                    handleGetContractorReviewsSummary(bid.contractor.id)
                  }
                }}
              >
                {renderStarRating(bid.contractor.average_score)}
                <span>({bid.contractor.average_score ? bid.contractor.average_score.toFixed(2) : '0'})</span>
              </div>
            </div>
          </div>
        </div>

        <div className="bid-details-container">
          <div className="bid-details-box">
            <div style={{ alignItems: 'baseline' }} className="offer-list-item flex-column">
              <OverlayTrigger
                key={`sum-small-${bid.id}`}
                placement="top"
                overlay={<Tooltip id={`sum-small-${bid.id}`}>Kwota</Tooltip>}
              >
                <p id={`sum-small-${bid.id}`} className="para">
                  <span>Kwota:</span> <span>{bid.bid} zł</span>
                  {bid.rate_type === BID_RATE_TYPE.hourly
                    ? ' /h'
                    : bid.rate_type === BID_RATE_TYPE.meter
                      ? ' /mb'
                      : bid.rate_type === BID_RATE_TYPE.sqm
                        ? ' /m2'
                        : ''}
                </p>
              </OverlayTrigger>
              {bid.need_more_info && (
                <span
                  style={{ fontSize: '10px', color: '#f9395f', fontWeight: 'normal' }}
                  className="need-more-info-text"
                >
                  Potrzebuję więcej informacji do dokładniejszej wyceny
                </span>
              )}
            </div>
            <div className="offer-list-item">
              <OverlayTrigger
                key={`date-small-${bid.id}`}
                placement="top"
                overlay={<Tooltip id={`date-small-${bid.id}`}>Data</Tooltip>}
              >
                <p style={{ textAlign: 'left' }} id={`date-small-${bid.id}`} className="para para-small">
                  {bid.timesince} temu
                </p>
              </OverlayTrigger>
            </div>
          </div>

          <div className="veryfi-icons">
            <div className="offer-list-item">
              <div
                className={`icon-container offer-icon-container ${contractor.is_email_verified ? 'verified' : 'not-verified'}`}
              >
                <OverlayTrigger
                  key={`${bid.id}-confirm-small-offer`}
                  placement="top"
                  overlay={
                    <Tooltip id={`${bid.id}-small-envelope`}>
                      {contractor.is_email_verified ? 'Zweryfikowany adres email' : 'Niezweryfikowany adres email'}
                    </Tooltip>
                  }
                >
                  <div id={`${bid.id}-small-envelope`} className="icon">
                    <i className="bi bi-envelope"></i>
                  </div>
                </OverlayTrigger>
              </div>
            </div>

            <div className="offer-list-item">
              <div
                className={`icon-container offer-icon-container ${contractor.is_phone_verified ? 'verified' : 'not-verified'}`}
              >
                <OverlayTrigger
                  key={`${bid.id}-confirm-small-offer`}
                  placement="top"
                  overlay={
                    <Tooltip id={`${bid.id}-small-phone`}>
                      {contractor.is_phone_verified
                        ? 'Zweryfikowany numer telefonu'
                        : 'Niezweryfikowany numer telefonu'}
                    </Tooltip>
                  }
                >
                  <div id={`${bid.id}-small-phone`} className="icon">
                    <i className="bi bi-telephone"></i>
                  </div>
                </OverlayTrigger>
              </div>
            </div>
          </div>

          {isOwner ? (
            <div className="bid-buttons">
              {!acceptedBid && (
                <div className="offer-list-item">
                  <OverlayTrigger
                    key={`${bid.id}-small-confirm-offer`}
                    placement="top"
                    overlay={
                      <Tooltip id={`${bid.id}-small-confirm-offer`}>
                        {disabledFuchaAction
                          ? 'Nie możesz zatwierdzić fuchy która jest nieaktywna'
                          : 'Zatwierdź wycenę'}
                      </Tooltip>
                    }
                  >
                    <button
                      id={`${bid.id}-small-confirm-offer`}
                      className={`eg-btn btn--primary btn--sm confirm ${disabledFuchaAction ? 'expired-fucha-btn' : ''}`}
                      onClick={() => {
                        setBidToAccept(bid)
                        setShowModal(true)
                      }}
                      disabled={disabledFuchaAction}
                    >
                      Zatwierdź
                    </button>
                  </OverlayTrigger>
                </div>
              )}

              <div className="offer-list-item">
                <OverlayTrigger
                  key={`${bid.id}-small-message`}
                  placement="top"
                  overlay={
                    <Tooltip id={`${bid.id}-small-message`}>
                      {disabledFuchaAction
                        ? 'Nie możesz napisać do fachowca. Fucha nie jest aktywna'
                        : 'Napisz wiadomość do fachowca'}
                    </Tooltip>
                  }
                >
                  <button
                    disabled={disabledFuchaAction}
                    id={`${bid.id}-small-message`}
                    className={`eg-btn btn--primary btn--sm ${disabledFuchaAction ? 'expired-fucha-btn' : ''}`}
                    onClick={() => handleChatStart(bid.contractor.id)}
                  >
                    <i className="bi bi-chat-left-dots"></i>
                  </button>
                </OverlayTrigger>
              </div>

              {isAccepted ? (
                <div className="offer-list-item">
                  <OverlayTrigger
                    key={`${bid.id}-small-contractor-review`}
                    placement="top"
                    overlay={
                      fucha.has_review ? (
                        <Tooltip id={`${bid.id}-small-contractor-review`}>
                          Już wystawiłeś recenzję dla tego fachowca
                        </Tooltip>
                      ) : (
                        <Tooltip id={`${bid.id}-small-contractor-review`}>Oceń fachowca</Tooltip>
                      )
                    }
                  >
                    <button
                      onClick={() => {
                        setShowReviewModal(true)
                      }}
                      id={`${bid.id}-small-contractor-review`}
                      className={`${fucha.has_review ? 'add-review-button' : 'btn--primary'} eg-btn btn--sm`}
                      disabled={fucha.has_review || false}
                    >
                      <i className="bi bi-star"></i>
                    </button>
                  </OverlayTrigger>
                </div>
              ) : null}
            </div>
          ) : (
            !bid.is_deleted && (
              <>
                <OverlayTrigger
                  key={`${bid.id}-small-delete`}
                  placement="top"
                  overlay={<Tooltip id={`${bid.id}-small-delete`}>Usuń swoją wycenę</Tooltip>}
                >
                  <button
                    id={`${bid.id}-small-delete`}
                    className="eg-btn btn--primary btn--sm remove-offer-btn"
                    onClick={() => {
                      setBidIdToDelete(bid.id)
                      setShowModal(true)
                    }}
                  >
                    <i className="bi bi-x-square"></i>
                  </button>
                </OverlayTrigger>
              </>
            )
          )}
        </div>
      </div>
    </>
  )
}

export default SmallScreenBidItem
