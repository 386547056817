import React, { useState, useEffect } from 'react'
import { Controller } from 'react-hook-form'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

import { useAlerts } from '../../../../../context/AlertContext'
import { REGIONS_WITH_COUNTRY, COUNTRY_WIDE } from '../../../../../_constants'

const PageThree = ({ control, errors, setValue, watch }) => {
  const { addAlert } = useAlerts()
  const [selectedCountiesOptions, setSelectedCountiesOptions] = useState([])
  const provinces = Object.keys(REGIONS_WITH_COUNTRY)
  const isCountryWide = watch('multiProvince')?.some((item) => item.value === COUNTRY_WIDE)
  const isAnyProvinceSelected = watch('multiProvince')?.length > 0

  const animatedComponents = makeAnimated()

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }

  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  }

  const handleChangeProvince = (values) => {
    const counties = []
    // Track all available counties
    let countiesValues = []

    const isCountryWide = values.some((item) => item.value === COUNTRY_WIDE)

    if (isCountryWide && values.length > 1) {
      setSelectedCountiesOptions([])
      setValue('multiCounty', [])
      setValue(
        'multiProvince',
        values.filter((item) => item.value === COUNTRY_WIDE)
      )
      addAlert('Nie można wybrać dodatkowych województw, jeśli zaznaczono opcję "Cała Polska"', 'info')
      return
    }

    values.forEach((item) => {
      counties.push({ label: item.value, options: toSelectOptions(REGIONS_WITH_COUNTRY[item.value]) })
      countiesValues = [...countiesValues, ...REGIONS_WITH_COUNTRY[item.value]]
    })

    setSelectedCountiesOptions(counties)
    // Remove selected counties if province has changed and set new provinces
    setValue(
      'multiCounty',
      watch('multiCounty').filter((item) => countiesValues.includes(item.value))
    )
  }

  const toSelectOptions = (values) => {
    return values.map((value) => {
      return { value: value, label: value }
    })
  }

  const getProvinceSelectOptions = () => {
    return toSelectOptions(provinces)
  }

  const provinceSelectOptions = getProvinceSelectOptions()

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  )

  useEffect(() => {
    handleChangeProvince(watch('multiProvince'))
  }, [])

  return (
    <div>
      <p>Wybierz teren na którym możesz wykonywać usługi</p>
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 mb-3">
          <div className="form-inner">
            <Controller
              control={control}
              render={({ field: { onChange, value, name, ref } }) => (
                <Select
                  inputRef={ref}
                  className="province-multiselect"
                  classNamePrefix="province-multiselect"
                  placeholder="Województwo"
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  value={value}
                  isMulti
                  options={provinceSelectOptions}
                  noOptionsMessage={() => 'Brak opcji'}
                  onChange={(selectedOption) => {
                    onChange(selectedOption)
                    handleChangeProvince(selectedOption)
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: '0.25rem',
                    colors: {
                      ...theme.colors,
                      primary25: '#f9395f',
                      primary: '#f9395f',
                    },
                  })}
                />
              )}
              name={'multiProvince'}
            />
          </div>
        </div>
        <div className="col-xl-12 col-lg-12 col-md-12">
          <div className="form-inner">
            <Controller
              control={control}
              render={({ field: { onChange, name, ref, value } }) => (
                <Select
                  inputRef={ref}
                  className="county-multiselect"
                  classNamePrefix="county-multiselect"
                  placeholder="Region"
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  isMulti
                  onChange={onChange}
                  value={value}
                  options={selectedCountiesOptions}
                  formatGroupLabel={formatGroupLabel}
                  noOptionsMessage={() => {
                    return !isAnyProvinceSelected && !isCountryWide
                      ? 'Najpierw wybierz województwo'
                      : isAnyProvinceSelected && isCountryWide
                        ? 'Nie możesz wybrać regionów, jeśli zaznaczono opcję "Cała Polska"'
                        : 'Brak opcji'
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: '0.25rem',
                    colors: {
                      ...theme.colors,
                      primary25: '#f9395f',
                      primary: '#f9395f',
                    },
                  })}
                />
              )}
              name={'multiCounty'}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageThree
