import React, { useState, useRef, useMemo, useEffect } from 'react'
import FuchaDetailCard from './fuchaCards/FuchaDetailCard'
import SimilarFuchyCard from './fuchaCards/SimilarFuchyCard'
import FuchaGalleryCard from './fuchaCards/FuchaGalleryCard'
import FuchaSpecificationsCard from './fuchaCards/FuchaSpecificationsCard'
import BidListItemsCard from './fuchaCards/BidListItemsCard'
import PlaceBidCard from './fuchaCards/PlaceBidCard'
import FuchaOwnerInfoCard from './fuchaCards/FuchaOwnerInfoCard'
import FuchaOwnerInfoSkeletonCard from './fuchaCards/FuchaOwnerInfoSkeletonCard'
import OwnerStatsCard from './fuchaCards/OwnerStatsCard'
import CongratsCard from './fuchaCards/CongratsCard'
import TryAnotherOneCard from './fuchaCards/TryAnotherOneCard'
import RegisterClientAccountCard from './fuchaCards/RegisterClientAccountCard'
import RegisterContractorAccountCard from './fuchaCards/RegisterContractorAccountCard'
import TextSkeletonCard from './fuchaCards/TextSkeletonCard'
import DisabledFuchaCard from './fuchaCards/DisabledFuchaCard'
import MaxNumberBiddingContractorsBiddingUsersCard from './fuchaCards/MaxNumberBiddingContractorsBiddingUsersCard'
import MaxBidsNumberForContractorCard from './fuchaCards/MaxBidsNumberForContractorCard'
import MaxNumberBiddingContractorsNonBiddingUsersCard from './fuchaCards/MaxNumberBiddingContractorsNonBiddingUsersCard'
import InactiveAccountCard from './fuchaCards/InactiveAccountCard'
import { useContractorActions } from '../../../_actions'
import { shouldDisplayServerSideError } from '../../../_helpers'
import { useAlerts } from '../../../context/AlertContext'
import { useClientActions } from '../../../_actions'
import {
  FUCHA_STATUS,
  MAX_NUMBER_OF_BIDDING_CONTRACTORS,
  MAX_NUMBER_OF_ACTIVE_BIDS,
  ADD_BID_COUNT_CHANGE,
  REMOVE_BID_COUNT_CHANGE,
} from '../../../_constants/fucha'
import { ACCOUNT_STATUS } from '../../../_constants/user'

const FuchaDetailsInfo = ({ fucha, setFucha, maybeUser, userLoading, fuchaLoading }) => {
  const offerListRef = useRef(null)
  const { addAlert } = useAlerts()
  const { getContractorReviewsSummary, getContractorActiveBidsCount } = useContractorActions()
  const { createContractorReview } = useClientActions()
  const [contractorReviewsSummary, setContractorReviewsSummary] = useState([])
  const [showReviewModal, setShowReviewModal] = useState(false)
  const [loadingBids, setLoadingBids] = useState(true)
  const [allBids, setAllBids] = useState([])
  const [ratingValues, setRatingValues] = useState({
    contact: 0,
    price: 0,
    quality: 0,
    time: 0,
  })
  const [contractorActiveBidCount, setContractorActiveBidCount] = useState({
    count: 0,
  })
  const disabledFuchaAction = [FUCHA_STATUS.EXPIRED, FUCHA_STATUS.CANCELLED, FUCHA_STATUS.ARCHIVED].includes(
    fucha?.status
  )

  const isContractor = maybeUser?.is_contractor
  const maxNumberOfBiddingContractors = fucha?.bid_count === MAX_NUMBER_OF_BIDDING_CONTRACTORS
  const maxBidsNumberForContractor = contractorActiveBidCount.count === MAX_NUMBER_OF_ACTIVE_BIDS
  const activeAccount = maybeUser?.account_status === ACCOUNT_STATUS.ACTIVE

  useEffect(() => {
    if (isContractor) {
      getContractorActiveBidsCount()
        .then((resp) => {
          setContractorActiveBidCount(resp)
        })
        .catch(() => {
          addAlert('Wystąpił błąd podczas pobierania danych', 'error')
        })
    }
  }, [maybeUser])

  const updateContractorActiveBidCount = (change) => {
    setContractorActiveBidCount((prev) => ({
      ...prev,
      count: prev.count + change,
    }))
  }

  const checkIfIsActiveContractorBidInAllBids = useMemo(() => {
    return allBids.some((bid) => bid.contractor.id === maybeUser.id && bid.is_deleted === false)
  }, [allBids, maybeUser])

  const scrollToOffer = () => {
    if (offerListRef.current) {
      offerListRef.current.scrollIntoView({ behavior: 'smooth' })

      setTimeout(() => {
        window.scrollBy({
          behavior: 'smooth',
        })
      }, 400)
    }
  }

  const handleCloseReviewModal = () => {
    setShowReviewModal(false)
  }

  const handleGetContractorReviewsSummary = (contractorId) => {
    getContractorReviewsSummary(contractorId)
      .then((data) => {
        setContractorReviewsSummary(data)
        setLoadingBids(false)
      })
      .catch((err) => {
        if (shouldDisplayServerSideError(err)) {
          addAlert(err.response.data.detail, 'error')
        } else {
          addAlert('Wystąpił błąd podczas pobierania danych o recenzjach', 'error')
        }
        setLoadingBids(false)
      })
  }

  const handleReviewRating = (type, rate) => {
    switch (type) {
      case 'time':
        setRatingValues((prev) => ({ ...prev, time: rate }))
        break
      case 'quality':
        setRatingValues((prev) => ({ ...prev, quality: rate }))
        break
      case 'contact':
        setRatingValues((prev) => ({ ...prev, contact: rate }))
        break
      case 'price':
        setRatingValues((prev) => ({ ...prev, price: rate }))
        break
      default:
        break
    }
  }

  const handleAddReviewForContractor = (setIsConfirming) => {
    const payload = {
      contractor_id: fucha.accepted_bid.contractor.id,
      fucha_id: fucha.id,
      completion_time: ratingValues.time,
      quality: ratingValues.quality,
      contact: ratingValues.contact,
      price: ratingValues.price,
    }

    if (Object.values(ratingValues).some((value) => value === 0)) {
      addAlert('Proszę wybrać ocenę od 1 do 5 przed wystawieniem opinii.', 'error')
      setIsConfirming(false)
      return
    }

    createContractorReview(payload)
      .then(() => {
        addAlert('Recenzja została wysłana pomyślnie!', 'success')
        fucha.has_review = true
        return getContractorReviewsSummary(fucha.accepted_bid.contractor.id)
      })
      .then((updatedData) => {
        setContractorReviewsSummary(updatedData)
        setFucha((prevFucha) => ({
          ...prevFucha,
          accepted_bid: {
            ...prevFucha.accepted_bid,
            contractor: {
              ...prevFucha.accepted_bid.contractor,
              average_score: updatedData.average_score,
            },
          },
        }))
        setAllBids((prevBids) =>
          prevBids.map((bid) => {
            if (bid.contractor.id === fucha.accepted_bid.contractor.id) {
              return {
                ...bid,
                contractor: {
                  ...bid.contractor,
                  average_score: updatedData.average_score,
                },
              }
            }
            return bid
          })
        )
      })
      .catch((err) => {
        if (shouldDisplayServerSideError(err)) {
          addAlert(err.response.data.detail, 'error', null)
        } else {
          addAlert('Nie udało się wysłać recenzji!', 'error')
        }
      })
      .finally(() => {
        handleCloseReviewModal()
        setIsConfirming(false)
      })
  }

  const isFuchaOwner = maybeUser?.id === fucha?.user_id
  const contractorReachedMaxNumberOfBids = maxBidsNumberForContractor && !checkIfIsActiveContractorBidInAllBids
  const fuchaReachedMaxNumberOfBiddingContractors =
    maxNumberOfBiddingContractors && !checkIfIsActiveContractorBidInAllBids
  const contractorHasInactiveAccount = isContractor && !activeAccount
  const contractorBidWins = isContractor && fucha?.accepted_bid?.contractor?.id === maybeUser.id

  const renderSideCard = () => {
    if (userLoading || fuchaLoading) {
      return <TextSkeletonCard />
    } else if ((isContractor || isFuchaOwner) && loadingBids) {
      return <TextSkeletonCard />
    }

    if (disabledFuchaAction) {
      return <DisabledFuchaCard isFuchaOwner={isFuchaOwner} />
    }

    if (fuchaReachedMaxNumberOfBiddingContractors && !isFuchaOwner) {
      return <MaxNumberBiddingContractorsNonBiddingUsersCard fuchaLoading={fuchaLoading} isContractor={isContractor} />
    }

    if (fuchaReachedMaxNumberOfBiddingContractors && isFuchaOwner) {
      return <MaxNumberBiddingContractorsBiddingUsersCard loadingBids={loadingBids} isFuchaOwner={isFuchaOwner} />
    }

    if (isContractor) {
      if (contractorHasInactiveAccount) {
        return <InactiveAccountCard />
      }
      if (contractorReachedMaxNumberOfBids) {
        return <MaxBidsNumberForContractorCard loadingBids={loadingBids} />
      }

      if (!fucha.is_bid_accepted) {
        return (
          <PlaceBidCard
            bids={allBids}
            disabledFuchaAction={disabledFuchaAction}
            fucha={fucha}
            loadingBids={loadingBids}
            onBidAdded={() => updateContractorActiveBidCount(ADD_BID_COUNT_CHANGE)}
            setBids={setAllBids}
          />
        )
      }

      if (contractorBidWins) {
        return <CongratsCard fucha={fucha} />
      }

      if (!contractorBidWins) {
        return <TryAnotherOneCard fucha={fucha} />
      }
    }

    if (isFuchaOwner) {
      return (
        <OwnerStatsCard
          bids={allBids}
          contractorReviewsSummary={contractorReviewsSummary}
          disabledFuchaAction={disabledFuchaAction}
          fucha={fucha}
          handleGetContractorReviewsSummary={handleGetContractorReviewsSummary}
          isOwner={maybeUser.id === fucha.user_id}
          loadingBids={loadingBids}
          scrollToOffer={scrollToOffer}
        />
      )
    }

    // Default fallback if no conditions match
    return <RegisterContractorAccountCard />
  }

  return (
    <>
      <div className="row">
        {<FuchaGalleryCard fucha={fucha} fuchaLoading={fuchaLoading} disabledFuchaAction={disabledFuchaAction} />}
        <div className="col-xl-6 col-lg-12 col-md-12 d-flex align-items-stretch flex-column">
          <div className="row">{renderSideCard()}</div>
          <div className="row">
            {userLoading || fuchaLoading ? (
              <TextSkeletonCard />
            ) : (isContractor || isFuchaOwner) && loadingBids ? (
              <FuchaOwnerInfoSkeletonCard />
            ) : !maybeUser ? (
              <RegisterClientAccountCard />
            ) : (
              <FuchaOwnerInfoCard
                allBids={allBids}
                disabledFuchaAction={disabledFuchaAction}
                fucha={fucha}
                isFuchaOwner={isFuchaOwner}
                maybeUser={maybeUser}
              />
            )}
          </div>
        </div>
      </div>

      {maybeUser && fucha ? (
        isContractor || maybeUser.id === fucha.user_id ? (
          <BidListItemsCard
            bids={allBids}
            contractorReviewsSummary={contractorReviewsSummary}
            disabledFuchaAction={disabledFuchaAction}
            fucha={fucha}
            handleAddReviewForContractor={handleAddReviewForContractor}
            handleCloseReviewModal={handleCloseReviewModal}
            handleGetContractorReviewsSummary={handleGetContractorReviewsSummary}
            handleReviewRating={handleReviewRating}
            isOwner={maybeUser.id === fucha.user_id}
            loadingBids={loadingBids}
            offerListRef={offerListRef}
            ratingValues={ratingValues}
            ref={offerListRef}
            setBids={setAllBids}
            setFucha={setFucha}
            setLoadingBids={setLoadingBids}
            setShowReviewModal={setShowReviewModal}
            showReviewModal={showReviewModal}
            onBidRemoved={() => updateContractorActiveBidCount(REMOVE_BID_COUNT_CHANGE)}
          />
        ) : null
      ) : null}
      <div className="row">
        <FuchaSpecificationsCard fucha={fucha} fuchaLoading={fuchaLoading} disabledFuchaAction={disabledFuchaAction} />
      </div>
      <div className="row">
        <FuchaDetailCard fucha={fucha} fuchaLoading={fuchaLoading} disabledFuchaAction={disabledFuchaAction} />
      </div>
      <div className="row similar-fuchy-container">
        <SimilarFuchyCard fucha={fucha} fuchaLoading={fuchaLoading} />
      </div>
    </>
  )
}

export default FuchaDetailsInfo
