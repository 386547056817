import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'
import { URL_PATHS } from '../../../../_constants/urls'
import { useAuth } from '../../../../context/AuthContext'
import { MAX_NUMBER_OF_BIDDING_CONTRACTORS } from '../../../../_constants/fucha'

// it's related to contractors and clients who are not involved in the bidding process but can see the fucha detail page
const MaxNumberBiddingContractorsNonBiddingUsersCard = ({ fuchaLoading, isContractor }) => {
  const { auth } = useAuth()

  const renderSkeleton = () => {
    return (
      <div className="card-body">
        <div>
          <h4>
            <Skeleton width={200} />
          </h4>
        </div>
        <div className="product-details-right wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
          <div className="bid-form">
            <div className="form-title">
              <p>
                <Skeleton count={5} />
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="card mb-3">
      {fuchaLoading ? (
        renderSkeleton()
      ) : (
        <div className="card-body">
          <div className="sidebar-widget-title">
            <h4>Ta fucha osiągnęła limit liczby wycen</h4>
            <span />
          </div>
          <div className="product-details-right wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
            <div className="bid-form">
              <div className="form-title">
                {!isContractor ? (
                  <p>
                    Ta fucha osiągnęła limit wycen – każda fucha przyjmuje do {MAX_NUMBER_OF_BIDDING_CONTRACTORS} wycen.
                    Ta liczba zmniejsza sie w przypadku gdy któryś z fachowców biorących udział w tej fusze wycofa swoją
                    wycenę.
                  </p>
                ) : (
                  <p>
                    {' '}
                    Niestety, osiągnięto limit liczby wycen na tę fuchę – każda fucha przyjmuje do{' '}
                    {MAX_NUMBER_OF_BIDDING_CONTRACTORS} wycen. Wciąż jednak masz szansę w przypadku gdy ktoś inny wycofa
                    swoją wycenę. Wystarczy regularnie sprawdzać tę fuchę.
                    <br />
                    <br />A na razie nie martw się, masz jeszcze wiele innych fuch do wyboru!
                  </p>
                )}
                {auth?.user.is_contractor && (
                  <Link
                    style={{ width: '165px', padding: '5px 12px', margin: '20px 0' }}
                    className="eg-btn btn--primary header-btn"
                    to={URL_PATHS.FUCHA_LISTING}
                  >
                    Przeglądaj Fuchy
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default MaxNumberBiddingContractorsNonBiddingUsersCard
