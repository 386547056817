import React from 'react'
import SimpleBox from '../../common/SimpleBox'
import { MAX_NUMBER_OF_BIDDING_CONTRACTORS } from '../../../_constants/fucha'

const WhyChooseUs = () => {
  return (
    <>
      <div className="choose-us-section pb-120">
        <img alt="images" src={process.env.PUBLIC_URL + '/images/bg/section-bg2.png'} className="section-bg-bottom" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="d-flex justify-content-center col-sm-12 col-md-10 col-lg-8 col-xl-7">
              <div className="section-title1">
                <h2 className="mb-4">Dlaczego warto wybrać mamfuche.pl?</h2>
                <p className="para mb-0">
                  <b>mamfuche.pl</b> to nowoczesna platforma, która łączy klientów z fachowcami w branży budowlanej,
                  remontowej, mechanicznej oraz projektowej. Dzięki szerokiemu zakresowi usług znajdziesz specjalistów z
                  całej Polski! <br />
                  <br />
                  Szukasz wykonawcy do remontu mieszkania, malowania ścian, układania płytek, naprawy auta, a może
                  potrzebujesz kreatywnego projektu wnętrza, aranżacji ogrodu lub mebli na wymiar?
                  <b>mamfuche.pl</b> pomoże Ci znaleźć najlepszych profesjonalistów! <br />
                  <br /> Zlecenia remontowe, usługi mechaniczne, projekty aranżacyjne i wiele innych – wszystko w jednym
                  miejscu na <b>mamfuche.pl</b>!
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center g-4">
            <SimpleBox
              title="Darmowe wystawianie zleceń"
              text="Wystawiaj zlecenia budowlane, naprawy samochodowe oraz projekty bez żadnych opłat i szybko dotrzyj do doświadczonych fachowców!"
              src={process.env.PUBLIC_URL + '/images/icons/free.svg'}
              icon
              number="01"
            />
            <SimpleBox
              title="Chroń swoje dane kontaktowe"
              text="Wystawiając zlecenie budowlane, naprawę samochodową lub projekt, nie musisz upubliczniać swoich danych kontaktowych. Do komunikacji używaj naszego czatu!"
              src={process.env.PUBLIC_URL + '/images/icons/safe-data.svg'}
              icon
              number="02"
            />
            <SimpleBox
              title="Wgodne szukanie fachowca!"
              text={`Wystaw zlecenie remontowe, mechaniczne lub projektowe, a wykonawcy sami się do Ciebie zgłoszą! Możesz otrzymać nawet do ${MAX_NUMBER_OF_BIDDING_CONTRACTORS} wycen na jedno zlecenie!`}
              src={process.env.PUBLIC_URL + '/images/icons/multiple.svg'}
              icon
              number="03"
            />
            <SimpleBox
              title="Szeroki wybór fachowców"
              text="Dostęp do licznych fachowców budowlanych, mechaników samochodowych i projektantów, którzy zapewnią wysoką jakość usług."
              src={process.env.PUBLIC_URL + '/images/icons/people.svg'}
              icon
              number="04"
            />
            <SimpleBox
              title="Łatwość rejestracji"
              text="Zarejestruj się w kilka minut i zacznij wystawiać zlecenia budowlane, naprawy samochodowe i projekty."
              src={process.env.PUBLIC_URL + '/images/icons/easy-signup.svg'}
              icon
              number="05"
            />
            <SimpleBox
              title="Recenzje i oceny fachowców"
              text="Sprawdź opinie innych użytkowników, aby wybrać najlepszego fachowca do realizacji Twojej fuchy!"
              src={process.env.PUBLIC_URL + '/images/icons/reviews.svg'}
              icon
              number="06"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default WhyChooseUs
