import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const Tile = ({ title, col, children, loading, bodyClassName = '', infoIcon, key, tooltipId, tooltipText }) => {
  return (
    <>
      {loading ? (
        <Skeleton containerClassName={`col-md-${col} col-sm-${col}`} height={150} />
      ) : (
        <div className={`col-md-${col} col-sm-${col}`}>
          <div className="dashboard-card hover-border1 wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".4s">
            <div className="header">
              <h5>{title}</h5>
              {infoIcon && (
                <OverlayTrigger key={key} placement="top" overlay={<Tooltip id={tooltipId}>{tooltipText}</Tooltip>}>
                  <i className="bi bi-info-circle"></i>
                </OverlayTrigger>
              )}
            </div>
            <div className={`body ${bodyClassName}`}>{children}</div>
          </div>
        </div>
      )}
    </>
  )
}

export default Tile
