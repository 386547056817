import React from 'react'
import Skeleton from 'react-loading-skeleton'

const FuchaOwnerInfoSkeletonCard = () => {
  return (
    <div className="card side-card">
      <div className="card-body">
        <div className="sidebar-widget-title">
          <h4>Osoba, która dodała fuchę</h4>
        </div>
        <div className="image-container">
          <div className="image-box">
            <Skeleton circle width={100} height={100} />
          </div>
        </div>
        <div className="client-details-container">
          <div className="information-container">
            <div style={{ marginTop: '70px' }} className="client-name-container">
              <div className="name-container">
                <p className="name">
                  <Skeleton width={120} />
                </p>
              </div>
            </div>
            <div className="icon-container">
              <div className="icon">
                <i className="bi bi-calendar-check"></i>
              </div>
              <p>
                <Skeleton width={150} />
              </p>
            </div>
            <div className="verification-info-container">
              <div className="icon-container">
                <div className="icon">
                  <i className="bi bi-envelope"></i>
                </div>
                <p>
                  <Skeleton width={150} />
                </p>
              </div>
              <div className="icon-container">
                <div className="icon">
                  <i className="bi bi-telephone"></i>
                </div>
                <p>
                  <Skeleton width={150} />
                </p>
              </div>
            </div>
            <div>
              <button className="eg-btn btn--primary btn--sm mt-3 mb-3 disabled-button" disabled>
                <Skeleton width={120} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FuchaOwnerInfoSkeletonCard
