import React, { useState, useEffect, useRef } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import * as Sentry from '@sentry/react'
import { pl } from 'date-fns/locale'
import DatePicker from 'react-datepicker'
import { Controller } from 'react-hook-form'

import Input from '../../../../common/Input'
import { REGIONS } from '../../../../../_constants'
import { useFuchaActions } from '../../../../../_actions'
import { WORK_STATE } from '../../../../../_constants'
import { useAlerts } from '../../../../../context/AlertContext'

const groupStyles = {
  alignItems: 'center',
  fontWeight: 'bold',
  color: '#f9395f',
  borderBottom: '1px solid #f9395f',
  width: '100%',
  fontSize: 14,
}

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
  </div>
)

const PageOne = ({ register, setValue, watch, errors, control, fucha, getValues }) => {
  const { addAlert } = useAlerts()
  const [counties, setCounties] = useState([])
  const [categoriesSelectOptions, setCategoriesSelectOptions] = useState([])
  const [typesOfWorkSelectOptions, setTypesOfWorkSelectOptions] = useState([])
  const [realizationDateFrom, setRealizationDateFrom] = useState('')
  const [realizationDateTo, setRealizationDateTo] = useState('')

  const { getCategories } = useFuchaActions()
  const initialCategoriesMapping = useRef({})

  const provinces = Object.keys(REGIONS)
  const realizationTime = watch('realizationTime')
  const provinceFieldWatch = watch('province')

  const animatedComponents = makeAnimated()

  const fetchInitialData = async () => {
    try {
      const { categoriesData, typesOfWorkData } = await getCategories()
      if (Object.keys(initialCategoriesMapping.current).length === 0) {
        categoriesData.forEach((category) => {
          const typesOfWork = category.types_of_work.map((type) => {
            return { value: type.id, label: type.name, categoryId: category.id }
          })
          // Separate the 'inne' type and append it to the end
          const inneType = typesOfWork.filter((type) => type.label === 'Inne')
          const otherTypes = typesOfWork.filter((type) => type.label !== 'Inne')
          initialCategoriesMapping.current[category.name] = [...otherTypes, ...inneType]
        })
      }

      setCategoriesSelectOptions(
        categoriesData.map((value) => {
          return { value: value.id, label: value.name }
        })
      )

      // Create the grouped options for types of work
      const groupedTypesOfWorkOptions = Object.entries(initialCategoriesMapping.current).map(
        ([categoryKey, categoryValues]) => {
          return { label: categoryKey, options: categoryValues }
        }
      )

      // Separate the 'Inne' category and append it to the end
      const inneCategory = groupedTypesOfWorkOptions.filter((category) => category.label === 'Inne')
      const otherCategories = groupedTypesOfWorkOptions.filter((category) => category.label !== 'Inne')

      const sortedGroupedTypesOfWorkOptions = [...otherCategories, ...inneCategory]

      setTypesOfWorkSelectOptions(sortedGroupedTypesOfWorkOptions)
    } catch (error) {
      Sentry.captureException(error)
      addAlert('Wystąpił błąd podczas pobierania kategorii, spróbuj ponownie później.', 'error', null)
    }
  }
  useEffect(() => {
    // Fire once to get initial data
    fetchInitialData()
  }, [])

  useEffect(() => {
    // If realizationTime changes and it's not 'realizationInDates', reset the dates
    if (realizationTime !== 'realizationInDates') {
      setRealizationDateFrom('')
      setRealizationDateTo('')
      // Also update the form values to empty strings
      setValue('realizationDateFrom', '')
      setValue('realizationDateTo', '')
    }

    const selectedProvince = watch('province')
    if (selectedProvince) {
      // Logic to set counties based on the selectedProvince
      const countiesForProvince = REGIONS[selectedProvince]
      setCounties(countiesForProvince.map((county) => ({ value: county, label: county })))
    }
  }, [realizationTime, setValue, watch('province')])

  const handleProvinceChange = (value) => {
    const counties = REGIONS[value]
    let countiesSelect
    for (let i = 0; i < counties.length; i++) {
      countiesSelect = counties.map((county) => {
        return { value: county, label: county }
      })
    }
    setCounties(countiesSelect)
  }

  const provinceValues = () => {
    return provinces.map((item) => {
      return {
        label: item,
        value: item,
      }
    })
  }

  const provinceOptions = provinceValues()

  const handleChangeRealizationDateFrom = (dateChange) => {
    setValue('realizationDateFrom', dateChange, {
      shouldDirty: true,
    })
    setRealizationDateFrom(dateChange)
  }

  const handleChangeRealizationDateTo = (dateChange) => {
    setValue('realizationDateTo', dateChange, {
      shouldDirty: true,
    })
    setRealizationDateTo(dateChange)
  }

  useEffect(() => {
    if (provinceFieldWatch && provinceFieldWatch !== getValues('province')) {
      handleProvinceChange(provinceFieldWatch)
    }
  }, [provinceFieldWatch])

  return (
    <div className="row">
      {/* ----------- TITLE AND SQM ------------- */}
      <div className="form-section-title">
        <p>Tytuł:</p>
      </div>
      <div className="explanation-text">
        <p>
          Tytuł może zawierać maksymalnie 50 znaków. Wybierz słowa, które najtrafniej i najbardziej precyzyjnie opisują
          Twoją fuchę, aby przyciągnąć uwagę potencjalnych wykonawców. Starannie dobrany tytuł ułatwi znalezienie Twojej
          fuchy i zwiększy jej atrakcyjność.
        </p>
      </div>
      <Input
        register={register}
        containerClassName={'col-xl-12 col-lg-12 col-md-12'}
        name="fuchaTitle"
        type="text"
        placeholder="Tytuł"
        error={errors.fuchaTitle?.message}
        onInput={(e) => {
          if (e.target.value.length > 50) {
            e.target.value = e.target.value.slice(0, 50)
          }
          const form = e.target.closest('form')
          const counter = form.querySelector('.title-text-counter:last-child')
          counter.textContent = `${e.target.value.length}/50`
        }}
        counter="0/50"
      />

      <div className="form-section-title">
        <p>Powierzchnia:</p>
      </div>
      <div className="explanation-text">
        <p>
          Podaj powierzchnię w metrach kwadratowych. W tym celu oblicz powierzchnię podłogi we wszystkich
          pomieszczeniach i dodaj je do siebie. Jeżeli to pole Cię nie dotyczy, wpisz 0.
        </p>
      </div>
      <Input
        register={register}
        containerClassName={'col-xl-12 col-lg-12 col-md-12'}
        name="sqm"
        type="number"
        placeholder="Powierzchnia [m2]"
        error={errors.sqm?.message}
      />

      {/* ----------- CATEGORIES ------------- */}
      <div className="form-section-title">
        <p>Kategoria i zakres prac:</p>
      </div>
      <div className="explanation-text">
        <p>
          Są to pola wielokrotnego wyboru. Wybierz jak najwięcej odpowiednich kategorii, aby jak najprecyzyjniej
          określić planowane zadania do wykonania. Dzięki temu zapewnisz dokładniejsze dopasowanie do wymagań projektu.
        </p>
      </div>
      {/* NOTE: This part is disabled for users and controlled by types of work on change method */}
      <div className="col-xl-12 col-lg-12 col-md-12">
        <div className="form-inner">
          <Controller
            control={control}
            render={({ field: { onChange, value, name, ref } }) => (
              <Select
                className="form-inner d-none"
                classNamePrefix="form-inner"
                name={name}
                inputRef={ref}
                value={value}
                options={categoriesSelectOptions}
                onChange={onChange}
                isMulti
                placeholder={<div className="select-placeholder-text">Kategorie</div>}
                isDisabled={true}
                isSearchable={false}
                noOptionsMessage={() => 'Brak opcji'}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: '0.25rem',
                  colors: {
                    ...theme.colors,
                    primary25: '#f9395f',
                    primary: '#f9395f',
                  },
                })}
                styles={{
                  option: (provided) => ({
                    ...provided,
                    cursor: 'pointer',
                    textAlign: 'left',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    textAlign: 'left',
                    fontSize: '15px',
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    textAlign: 'left',
                    fontSize: '15px',
                  }),
                  multiValue: (provided) => ({
                    ...provided,
                  }),
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                  valueContainer: (provided, state) => ({
                    ...provided,
                    marginBottom: state.hasValue ? '-34px' : 'defaultMarginBottom',
                  }),
                }}
              />
            )}
            name={'categories'}
          />
          {/* {errors && (
            <div style={{ marginTop: '-17px', marginBottom: '30px' }} className="error-message">
              {errors.categories?.message}
            </div>
          )} */}
        </div>
      </div>
      <div className="col-xl-12 col-lg-12 col-md-12">
        <div className="form-inner">
          <Controller
            control={control}
            render={({ field: { onChange, value, name, ref } }) => (
              <Select
                inputRef={ref}
                className="form-inner"
                classNamePrefix="form-inner"
                components={animatedComponents}
                value={value}
                options={typesOfWorkSelectOptions}
                formatGroupLabel={formatGroupLabel}
                name={name}
                onChange={(selectedOptions) => {
                  onChange(selectedOptions)
                  // Pull categories assigned to the selected types of work and set them in the categories field
                  const categoryIds = selectedOptions.map((option) => option.categoryId)
                  const selectedCategories = categoriesSelectOptions.filter((option) =>
                    categoryIds.includes(option.value)
                  )
                  setValue('categories', selectedCategories)
                }}
                isMulti
                placeholder={<div className="select-placeholder-text">Wybierz Kategorie i Zakres Prac</div>}
                isSearchable={false}
                noOptionsMessage={() => 'Brak opcji'}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: '0.25rem',
                  colors: {
                    ...theme.colors,
                    primary25: '#f9395f',
                    primary: '#f9395f',
                  },
                })}
                styles={{
                  option: (provided) => ({
                    ...provided,
                    cursor: 'pointer',
                    textAlign: 'left',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    textAlign: 'left',
                    fontSize: '15px',
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    textAlign: 'left',
                    fontSize: '15px',
                  }),
                  multiValue: (provided) => ({
                    ...provided,
                  }),
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                  valueContainer: (provided, state) => ({
                    ...provided,
                    marginBottom: state.hasValue ? '-34px' : 'defaultMarginBottom',
                  }),
                }}
              />
            )}
            name={'typesOfWork'}
          />
          {errors && (
            <div style={{ marginTop: '-17px', marginBottom: '30px' }} className="error-message">
              {errors.categories?.message || errors.typesOfWork?.message ? errors.typesOfWork?.message : null}
            </div>
          )}
        </div>
      </div>

      {/* ----------- MIEJSCE REALIZACJI ------------- */}
      <div className="form-section-title">
        <p>Miejsce realizacji:</p>
      </div>
      <div className="col-xl-12 col-lg-12 col-md-12">
        <div className="form-inner">
          <Controller
            control={control}
            render={({ field: { onChange, value, name, ref } }) => (
              <Select
                className="form-inner"
                classNamePrefix="form-inner"
                name={name}
                inputRef={ref}
                value={provinceOptions.find((province) => {
                  return province.value === value
                })}
                options={provinceOptions}
                noOptionsMessage={() => 'Brak opcji'}
                onChange={(selectedOption) => {
                  onChange(selectedOption.value)
                }}
                placeholder={<div className="select-placeholder-text">Wybierz Województwo</div>}
                isSearchable={false}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: '0.25rem',
                  colors: {
                    ...theme.colors,
                    primary25: '#f9395f',
                    primary: '#f9395f',
                  },
                })}
                styles={{
                  option: (provided) => ({
                    ...provided,
                    cursor: 'pointer',
                    textAlign: 'left',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    textAlign: 'left',
                    fontSize: '15px',
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    textAlign: 'left',
                    fontSize: '15px',
                  }),
                  multiValue: (provided) => ({
                    ...provided,
                    textTransform: 'capitalize',
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    padding: '0 8px',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
              />
            )}
            name={'province'}
          />
          {errors && (
            <div style={{ marginTop: '-17px', marginBottom: '30px' }} className="error-message">
              {errors.province?.message}
            </div>
          )}
        </div>
      </div>
      <div className="col-xl-12 col-lg-12 col-md-12">
        <div className="form-inner">
          <Controller
            control={control}
            render={({ field: { onChange, name, ref, value } }) => (
              <Select
                inputRef={ref}
                className="form-inner"
                classNamePrefix="form-inner"
                name={name}
                options={counties}
                value={counties.find((option) => option.value === value)}
                onChange={(selectedOption) => {
                  onChange(selectedOption.value)
                }}
                placeholder={<div className="select-placeholder-text">Wybierz Region</div>}
                isSearchable={false}
                isDisabled={counties.length === 0 ? true : false}
                noOptionsMessage={() => 'Brak opcji'}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: '#f9395f',
                    primary: '#f9395f',
                  },
                })}
                styles={{
                  option: (provided) => ({
                    ...provided,
                    cursor: 'pointer',
                    textAlign: 'left',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    textAlign: 'left',
                    fontSize: '15px',
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    textAlign: 'left',
                    fontSize: '15px',
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    padding: '0 8px',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
              />
            )}
            name={'county'}
          />
          {errors && (
            <div style={{ marginTop: '-17px', marginBottom: '30px' }} className="error-message">
              {errors.county?.message}
            </div>
          )}
        </div>
      </div>

      {/* ----------- WORK STATE ------------- */}
      <div className="form-section-title">
        <p>Status:</p>
      </div>
      <div className="col-xl-4 col-md-12">
        <div className="inputGroup">
          <input id="work-state-renovation" type="radio" {...register('workState')} value={WORK_STATE.RENOVATION} />
          <label htmlFor="work-state-renovation">Remont</label>
        </div>
        {errors && (
          <div style={{ marginTop: '-17px', marginBottom: '17px' }} className="error-message">
            {errors.workState?.message}
          </div>
        )}
      </div>
      <div className="col-xl-4 col-md-12">
        <div className="inputGroup">
          <input id="work-state-new-build" type="radio" {...register('workState')} value={WORK_STATE.NEW_BUILD} />
          <label htmlFor="work-state-new-build">Stan deweloperski</label>
        </div>
        {errors && (
          <div style={{ marginTop: '-17px', marginBottom: '17px' }} className="error-message">
            {errors.workState?.message}
          </div>
        )}
      </div>
      <div className="col-xl-4 col-md-12">
        <div className="inputGroup">
          <input id="work-state-other" type="radio" {...register('workState')} value={WORK_STATE.OTHER} />
          <label htmlFor="work-state-other">Inne</label>
        </div>
        {errors && (
          <div style={{ marginTop: '-17px', marginBottom: '17px' }} className="error-message">
            {errors.workState?.message}
          </div>
        )}
      </div>

      {/* ----------- REALIZATION ASAP ------------- */}
      <div className="form-section-title">
        <p>Termin realizacji:</p>
      </div>
      <div className="col-xl-6 col-md-6">
        <div className="inputGroup">
          <input id="realizationAsap" type="radio" {...register('realizationTime')} value="realizationAsap" />
          <label htmlFor="realizationAsap">Jak Najszybciej</label>
        </div>
        {errors && (
          <div style={{ marginTop: '-17px', marginBottom: '35px' }} className="error-message">
            {errors.realizationTime?.message}
          </div>
        )}
      </div>

      {/* ----------- REALIZATION ASAP ------------- */}
      <div className="col-xl-6 col-md-6">
        <div className="inputGroup">
          <input id="realizationNotAsap" type="radio" {...register('realizationTime')} value="realizationNotAsap" />
          <label htmlFor="realizationNotAsap">Dostosuję się do wykonawcy</label>
        </div>
        {errors && (
          <div style={{ marginTop: '-17px', marginBottom: '35px' }} className="error-message">
            {errors.realizationTime?.message}
          </div>
        )}
      </div>

      {/* ----------- REALIZATION IN DATES: ------------- */}
      <div className="col-xl-12 col-md-12">
        <div className="inputGroup">
          <input id="realizationInDates" type="radio" {...register('realizationTime')} value="realizationInDates" />
          <label htmlFor="realizationInDates">Wyknonanie w terminie: </label>
        </div>
        {errors && (
          <div style={{ marginTop: '-17px', marginBottom: '25px' }} className="error-message">
            {errors.realizationTime?.message}
          </div>
        )}
      </div>

      {/* ----------- REALIZATION DATES ------------- */}
      <div className="col-xl-6  col-md-6">
        <div className="date-realization-container">
          <div className="form-section-title data-box">
            <p style={{ color: realizationTime !== 'realizationInDates' ? 'rgb(105, 105, 105, 0.43)' : '#696969' }}>
              Data od:
            </p>
          </div>
          <div className="form-inner">
            <Controller
              name="realizationDateFrom"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  selected={field.value || realizationDateFrom}
                  placeholderText="Wybierz datę"
                  onChange={(date) => {
                    field.onChange(date)
                    handleChangeRealizationDateFrom(date)
                  }}
                  dateFormat="dd/MM/yyyy"
                  disabled={realizationTime !== 'realizationInDates'}
                  minDate={new Date()}
                  locale={pl}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="col-xl-6 col-lg-12 col-md-6">
        <div className="date-realization-container">
          <div className="form-section-title data-box">
            <p style={{ color: realizationTime !== 'realizationInDates' ? 'rgb(105, 105, 105, 0.43)' : '#696969' }}>
              Data do:
            </p>
          </div>
          <div className="form-inner">
            <Controller
              name="realizationDateTo"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  selected={field.value || realizationDateTo}
                  placeholderText="Wybierz datę"
                  onChange={(date) => {
                    field.onChange(date)
                    handleChangeRealizationDateTo(date)
                  }}
                  dateFormat="dd/MM/yyyy"
                  disabled={realizationTime !== 'realizationInDates'}
                  minDate={new Date()}
                  locale={pl}
                />
              )}
            />
          </div>
        </div>
      </div>

      {/* ----------- DESCRIPTION ------------- */}
      <div className="form-section-title">
        <p>Opis fuchy:</p>
      </div>
      <div className="explanation-text">
        <p>
          Opisz zakres prac do wykonania. Podaj jak najwięcej informacji, takich jak specyficzne wymagania, materiały do
          wykorzystania, oczekiwane standardy wykonania lub ewentualne ograniczenia czasowe. <br />
        </p>
      </div>
      <div className="bio-container">
        <textarea
          style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
          {...register('fuchaDescription')}
          placeholder="Opisz w kilku zdaniach fuchę:"
          onInput={(e) => {
            if (e.target.value.length > 1500) {
              e.target.value = e.target.value.slice(0, 1500)
            }
            const form = e.target.closest('form')
            const counter = form.querySelector('.text-counter:last-child')
            counter.textContent = `${e.target.value.length}/1500`
          }}
        />
        {errors && <div className="error-message">{errors.fuchaDescription?.message}</div>}

        <div className="text-counter">0/1500</div>
      </div>
      <div className="explanation-text">
        <p>
          <span className="text-danger">
            Pamiętaj! Nie musisz podawać danych kontaktowych w opisie fuchy! Do komunikacji z fachowcami używaj naszego
            czatu!
          </span>
        </p>
      </div>
    </div>
  )
}

export default PageOne
