import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Rating } from 'react-simple-star-rating'
import AccountIcon from '../../../../assets/images/icons/account.svg'
import { useAlerts } from '../../../../context/AlertContext'
import { useContractorActions } from '../../../../_actions/'
import { useFuchaActions, useClientActions } from '../../../../_actions/'
import { FUCHA_STATUS } from '../../../../_constants'
import { STAR_COLORS } from '../../../../_constants/values'
import ConfirmModal from '../../../common/ConfirmModal'
import UserReviewsSummaryModal from '../../../common/UserReviewsSummaryModal'
import { shouldDisplayServerSideError } from '../../../../_helpers'
import { history } from '../../../../_helpers'
import BigScreenBidItem from './BigScreenBidItem'
import SmallScreenBidItem from './SmallScreenBidItem'

const BidListItemsCard = ({
  bids,
  contractorReviewsSummary,
  disabledFuchaAction,
  fucha,
  handleAddReviewForContractor,
  handleCloseReviewModal,
  handleGetContractorReviewsSummary,
  handleReviewRating,
  isOwner,
  loadingBids,
  offerListRef,
  onBidRemoved,
  ratingValues,
  setBids,
  setFucha,
  setLoadingBids,
  setShowReviewModal,
  showReviewModal,
}) => {
  const { addAlert } = useAlerts()
  const { getFuchaBids, startChat } = useFuchaActions()
  const { acceptBid } = useClientActions()
  const { deleteBid } = useContractorActions()
  const [showModal, setShowModal] = useState(false)
  const [showContractorReviewsSummaryModal, setShowContractorReviewsSummaryModal] = useState(false)
  const [selectedBid, setSelectedBid] = useState(null)
  const [bidIdToDelete, setBidIdToDelete] = useState(null)
  const [bidToAccept, setBidToAccept] = useState(null)
  const acceptedBid = fucha.accepted_bid ? fucha.accepted_bid : null

  const handleCloseModal = () => {
    setShowModal(false)
    setBidIdToDelete(null)
    setBidToAccept(null)
  }

  const handleCloseContractorReviewsSummaryModal = () => {
    setShowContractorReviewsSummaryModal(false)
    setSelectedBid(null)
  }

  const handleOpenContractorReviewsSummaryModal = (bid) => {
    setSelectedBid(bid)
    setShowContractorReviewsSummaryModal(true)
  }

  const handleDeleteBid = (setIsConfirming) => {
    deleteBid(bidIdToDelete)
      .then(() => {
        setBids((prev) =>
          prev.map((item) => {
            if (item.id === bidIdToDelete) {
              return { ...item, is_deleted: true }
            }
            return item
          })
        )
        addAlert('Oferta została usunięta', 'success')
        setFucha((prev) => ({ ...prev, bid_count: prev - 1 }))
        onBidRemoved()
      })
      .catch((err) => {
        if (shouldDisplayServerSideError(err)) {
          addAlert(err.response.data.detail, 'error')
        } else {
          addAlert('Wystąpił błąd podczas usuwania oferty', 'error')
        }
      })
      .finally(() => {
        setBidIdToDelete(false)
        setShowModal(false)
        setIsConfirming(false)
      })
  }

  const handleConfirmBid = (setIsConfirming) => {
    const payload = {
      bid_id: bidToAccept.id,
      contractor_id: bidToAccept.contractor.id,
      fucha_id: fucha.id,
    }
    acceptBid(payload)
      .then((data) => {
        setFucha(data)
        setBidToAccept(null)
        addAlert('Wycena została zaakceptowana', 'success')
      })
      .catch((err) => {
        if (shouldDisplayServerSideError(err)) {
          addAlert(err.response.data.detail, 'error')
        } else {
          addAlert('Wystąpił błąd podczas zaakceptowania oferty', 'error')
        }
      })
      .finally(() => {
        setShowModal(false)
        setBidToAccept(null)
        setIsConfirming(false)
      })
  }

  const handleChatStart = (contractorId) => {
    startChat(fucha.id, contractorId)
      .then((data) => {
        history.push('/chat?chatId=' + data.channel_id)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    getFuchaBids(fucha.id)
      .then((data) => {
        setBids(data)
        setLoadingBids(false)
      })
      .catch((err) => {
        if (shouldDisplayServerSideError(err)) {
          addAlert(err.response.data.detail, 'error')
        } else {
          addAlert('Wystąpił błąd podczas pobierania ofert', 'error')
        }
      })
  }, [])

  const renderBid = (bid) => {
    return (
      <React.Fragment key={bid.id}>
        <BigScreenBidItem
          acceptedBid={acceptedBid}
          bid={bid}
          disabledFuchaAction={disabledFuchaAction}
          fucha={fucha}
          handleChatStart={handleChatStart}
          handleGetContractorReviewsSummary={handleGetContractorReviewsSummary}
          handleOpenContractorReviewsSummaryModal={handleOpenContractorReviewsSummaryModal}
          isOwner={isOwner}
          setBidIdToDelete={setBidIdToDelete}
          setBidToAccept={setBidToAccept}
          setShowModal={setShowModal}
          setShowReviewModal={setShowReviewModal}
        />
        <SmallScreenBidItem
          acceptedBid={acceptedBid}
          bid={bid}
          disabledFuchaAction={disabledFuchaAction}
          fucha={fucha}
          handleChatStart={handleChatStart}
          handleGetContractorReviewsSummary={handleGetContractorReviewsSummary}
          handleOpenContractorReviewsSummaryModal={handleOpenContractorReviewsSummaryModal}
          isOwner={isOwner}
          setBidIdToDelete={setBidIdToDelete}
          setBidToAccept={setBidToAccept}
          setShowModal={setShowModal}
          setShowReviewModal={setShowReviewModal}
        />
      </React.Fragment>
    )
  }

  const renderBids = () => {
    const deletedBids = bids.filter((bid) => bid.is_deleted)
    const activeBids = bids.filter((bid) => !bid.is_deleted)
    if (isOwner) {
      if (acceptedBid) {
        const rejectedBids = bids.filter((bid) => bid.id !== acceptedBid.id)
        return (
          <div className={`offers-list-container ${disabledFuchaAction ? 'expired-fucha' : ''}`}>
            <h6>Zatwierdzona wycena</h6>
            {renderBid(acceptedBid)}
            {rejectedBids.length > 0 && (
              <>
                <h6>Odrzucone</h6>
                {rejectedBids.map((bid) => {
                  return renderBid(bid)
                })}
              </>
            )}
          </div>
        )
      } else {
        return (
          <div className="offers-list-container">
            {bids.map((bid) => {
              return renderBid(bid)
            })}
          </div>
        )
      }
    }
    if (fucha.status === FUCHA_STATUS.ACTIVE) {
      return (
        <div className="offers-list-container">
          {activeBids.length > 0 ? <h6>Aktwyne</h6> : null}
          {activeBids.map((bid) => {
            return renderBid(bid)
          })}
          {!isOwner && deletedBids.length > 0 ? (
            <>
              <h6>Usunięte</h6>
              {deletedBids.map((bid) => {
                return renderBid(bid)
              })}
            </>
          ) : null}
        </div>
      )
    } else {
      if (fucha.accepted_bid) {
        return (
          <div className="offers-list-container">
            <h6>Zakończone - Twoja wycena wygrała! Gratulujemy!</h6>
            {renderBid(fucha.accepted_bid)}
          </div>
        )
      }
      return (
        <div className="offers-list-container">
          <h6>Zakończone - Niestety Twoja wycena nie została wybrana</h6>
          {bids.map((bid) => {
            return renderBid(bid)
          })}
        </div>
      )
    }
  }

  const renderSkeletonBids = () => {
    return (
      <div className="offers-list-container">
        {[1, 2, 3].map((index) => (
          <div key={index} className="offer-list-item-container">
            <div className="offer-image-container">
              <div className="offer-image-box">
                <Skeleton height={50} width={50} borderRadius={'50%'} />
              </div>
            </div>
            <div className="offer-list-item">
              <p className="para name">
                <Skeleton width={100} />
              </p>
            </div>
            <div className="offer-list-item">
              <Skeleton width={100} />
            </div>
            <div className="offer-list-item">
              <Skeleton width={100} />
            </div>
            <div className="offer-list-item">
              <Skeleton width={100} />
            </div>
            <div className="offer-list-item">
              <Skeleton width={100} />
            </div>
            <div className="offer-list-item">
              <Skeleton width={100} />
            </div>
            <div className="offer-list-item offer-btn-container">
              <Skeleton width={100} />
            </div>
          </div>
        ))}
      </div>
    )
  }

  const renderNoBids = () => {
    return (
      <div className="product-details-right  wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
        <div className="bid-form">
          <div className="form-title">
            <h5>{isOwner ? 'Nie ma jeszcze dostępnych wycen' : 'Brak wysłanych wycen'}</h5>
            <p className="para">
              {isOwner
                ? 'Spokojnie, fachowcy przeglądają Twoje zgłoszenie. Sprawdź ponownie za chwilę, a na pewno wkrótce pojawi się wycena, która Ci odpowiada!'
                : ''}
            </p>
          </div>
        </div>
      </div>
    )
  }

  const renderReviewModal = () => {
    return (
      <ConfirmModal
        header="Oceń tego fachowca!"
        showModal={showReviewModal}
        handleClose={handleCloseReviewModal}
        handleConfirm={handleAddReviewForContractor}
        size="lg"
        className="contractor-review-modal"
      >
        <img src={process.env.PUBLIC_URL + '/images/bg/client-bg.png'} className="review-bg" alt="" />
        <div className="row align-items-center flex-column review-container">
          <div className="col-lg-12 w-100">
            <div className="d-flex justify-content-center align-items-center h-100 w-100">
              <div className="image-container">
                <img
                  id={`contractor-img-${acceptedBid.id}`}
                  src={acceptedBid.contractor.image ? acceptedBid.contractor.image : AccountIcon}
                  alt="Admin"
                  className="rounded-circle"
                  width="110"
                />
              </div>
            </div>
          </div>

          <div className="col-lg-12 w-100">
            <div className="d-flex flex-column align-items-center h-100 w-100 contractor-name-box">
              <p>{acceptedBid.contractor.name}</p>
            </div>
          </div>

          <div className="col-lg-12 w-100" style={{ paddingBottom: '20px' }}>
            <div className="d-flex flex-column align-items-center h-100 w-100">
              <div className="rating-box">
                <div className="text-part">
                  <p>Jakość wykonania</p>
                </div>
                <div className="stars-part">
                  <Rating
                    fillColorArray={[
                      STAR_COLORS.poor,
                      STAR_COLORS.belowAverage,
                      STAR_COLORS.average,
                      STAR_COLORS.good,
                      STAR_COLORS.excellent,
                    ]}
                    tooltipDefaultText="Jakość wykonania"
                    titleSeparator="z"
                    size={23}
                    onClick={(rating) => handleReviewRating('quality', rating)}
                    value={ratingValues.quality}
                  />
                </div>
              </div>
              <div className="rating-box">
                <div className="text-part">
                  <p>Koszt wykonania</p>
                </div>
                <div className="stars-part">
                  <Rating
                    fillColorArray={[
                      STAR_COLORS.poor,
                      STAR_COLORS.belowAverage,
                      STAR_COLORS.average,
                      STAR_COLORS.good,
                      STAR_COLORS.excellent,
                    ]}
                    titleSeparator="z"
                    size={23}
                    onClick={(rating) => handleReviewRating('price', rating)}
                    value={ratingValues.price}
                    initialValue={0}
                  />
                </div>
              </div>
              <div className="rating-box">
                <div className="text-part">
                  <p>Czas wykonania</p>
                </div>
                <div className="stars-part">
                  <Rating
                    fillColorArray={[
                      STAR_COLORS.poor,
                      STAR_COLORS.belowAverage,
                      STAR_COLORS.average,
                      STAR_COLORS.good,
                      STAR_COLORS.excellent,
                    ]}
                    tooltipDefaultText="Czas Wykonania"
                    titleSeparator="z"
                    size={23}
                    onClick={(rating) => handleReviewRating('time', rating)}
                    value={ratingValues.time}
                  />
                </div>
              </div>
              <div className="rating-box">
                <div className="text-part">
                  <p>Kontakt z fachowcem</p>
                </div>
                <div className="stars-part">
                  <Rating
                    fillColorArray={[
                      STAR_COLORS.poor,
                      STAR_COLORS.belowAverage,
                      STAR_COLORS.average,
                      STAR_COLORS.good,
                      STAR_COLORS.excellent,
                    ]}
                    tooltipDefaultText="Kontakt z Fachowcem"
                    titleSeparator="z"
                    size={23}
                    onClick={(rating) => handleReviewRating('contact', rating)}
                    value={ratingValues.contact}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ConfirmModal>
    )
  }

  return (
    <div className="row" ref={offerListRef}>
      <div className="col-md-12 offer-items-container">
        <div className={`card ${disabledFuchaAction ? 'expired-fucha' : ''}`}>
          <div className="card-body">
            <div className="sidebar-widget-title">
              <h4>{isOwner ? 'Wyceny Od Fachowców' : 'Twoje Wyceny'}</h4>
              <span />
            </div>
            {loadingBids ? renderSkeletonBids() : bids.length === 0 ? renderNoBids() : renderBids()}
          </div>
        </div>
      </div>
      <ConfirmModal
        header={isOwner ? 'Czy na pewno chcesz wybrać tę wycenę?' : 'Czy na pewno chcesz usunąć tę wycenę?'}
        showModal={showModal}
        handleClose={handleCloseModal}
        handleConfirm={isOwner ? handleConfirmBid : handleDeleteBid}
        size="lg"
        className="bid-modal"
      >
        {isOwner ? (
          <>
            <div className="text-center">
              <p className="para">
                Po zatwierdzeniu Twoje ogłoszenie zostanie <b>sfinalizowane</b>, a wszystkie inne wyceny zostaną
                odrzucone. <b>Dane kontaktowe</b> zostaną przekazane wybranemu fachowcowi, aby mógł się z Tobą
                skontaktować i omówić szczegóły zlecenia.
              </p>
            </div>
          </>
        ) : (
          <>
            <p className="para">
              Pamiętaj, że możesz złożyć <b>maksymalnie dwie wyceny</b>. Usunięcie tej wyceny{' '}
              <b>nie umożliwi dodania kolejnej</b>, jeśli już wykorzystałeś limit dwóch wycen.
            </p>
          </>
        )}
      </ConfirmModal>
      {showReviewModal && renderReviewModal()}
      {showContractorReviewsSummaryModal && selectedBid && (
        <UserReviewsSummaryModal
          showModal={showContractorReviewsSummaryModal}
          handleClose={handleCloseContractorReviewsSummaryModal}
          reviewsSummary={contractorReviewsSummary}
          loading={loadingBids}
          userId={selectedBid.contractor.id}
          userImage={selectedBid.contractor.image}
          userName={
            selectedBid.contractor.enable_display_company_name && selectedBid.contractor.company_name
              ? selectedBid.contractor.company_name
              : selectedBid.contractor.name
          }
        />
      )}
    </div>
  )
}

export default BidListItemsCard
