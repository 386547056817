import React from 'react'

const PageFour = ({ register, errors }) => {
  return (
    <>
      <p>
        Opisz siebie i swoją działalność. Spróbuj dodać najistotniejsze informacje. To bardzo ważny element budujący
        wiarygodność Twojej osoby!{' '}
      </p>
      <div className="row">
        <div className="bio-container">
          <textarea
            style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
            {...register('bioContractor')}
            placeholder="Napisz kilka zdań o sobie:"
            rows={6}
            onInput={(e) => {
              if (e.target.value.length > 2500) {
                e.target.value = e.target.value.slice(0, 2500)
              }
              const form = e.target.closest('form')
              const counter = form.querySelector('.text-counter:last-child')
              counter.textContent = `${e.target.value.length}/2500`
            }}
          />
          <div className="text-counter">0/2500</div>
        </div>
      </div>
    </>
  )
}

export default PageFour
