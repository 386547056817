import React, { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Breadcrumb from '../../common/Breadcrumb'
import { useAuth } from '../../../context/AuthContext'
import { useForm } from 'react-hook-form'
import { SignUpSchema } from './schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { URL_PATHS } from '../../../_constants/urls'
import { useHistory } from 'react-router-dom'
import { useAlerts } from '../../../context/AlertContext'
import { useUserActions } from '../../../_actions'
import {
  scrollToTop,
  metaPixelEvent,
  META_EVENT_NAME,
  googleAdsEvent,
  isGoogleAdsUser,
  GOOGLE_EVENT_NAME,
} from '../../../_helpers'
import SignupForm from './SignupForm'

const SignUpClient = () => {
  const { addAlert } = useAlerts()
  const { isAuth } = useAuth()
  const userActions = useUserActions()

  useEffect(() => {
    metaPixelEvent(META_EVENT_NAME.VIEW_CONTENT)
  }, [])

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    resolver: zodResolver(SignUpSchema),
  })

  let history = useHistory()

  const onSubmit = async (data) => {
    const payload = {
      email: data.email.toLowerCase(),
      password: data.password,
      first_name: data.firstName,
      last_name: data.lastName,
      phone_number: data.phoneNumber,
      is_marketing_enabled: data.marketingComms,
      is_external_marketing_enabled: data.externalMarketingComms,
      is_contractor: false,
    }

    try {
      await userActions.register(payload)
      addAlert('Konto utworzone pomyślnie! Sprawdź skrzynkę pocztową i potwierdź swój adres e-mail.', 'success')
      metaPixelEvent(META_EVENT_NAME.COMPLETE_REGISTRATION, { is_contractor: false })
      googleAdsEvent(GOOGLE_EVENT_NAME.COMPLETE_REGISTRATION, {
        method: isGoogleAdsUser() ? 'Google' : 'unknown',
        value: 0,
        currency: 'PLN',
        is_contractor: true,
      })
      reset()
      scrollToTop()
      history.push(URL_PATHS.INFO_AFTER_SIGNUP)
    } catch (error) {
      if (error.response?.data?.display && error.response?.data?.detail) {
        addAlert(error.response.data.detail, 'error')
      } else {
        addAlert('Wystąpił błąd podczas tworzenia konta. Spróbuj ponownie za chwilę.', 'error', null)
      }
    }
  }

  if (isAuth) {
    scrollToTop()
    return <Redirect to={URL_PATHS.DASHBOARD} />
  }

  return (
    <div>
      <div className="client">
        <Breadcrumb pageName="Masz fuchę do zlecenia?" pageTitle="Zarejestruj konto i zacznij wystawiać fuchy!" />
      </div>
      <div className="signup-section pt-120 pb-120">
        <img alt="imges" src={process.env.PUBLIC_URL + '/images/bg/client-bg.svg'} className="img-fluid section-bg" />
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-xl-6 col-lg-8 col-md-10">
              <div className="form-wrapper wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s">
                <div className="form-title">
                  <h3>Zarejestruj konto i zacznij wystawiać fuchy!</h3>
                  <div className="d-flex justify-content-between align-items-center mt-4">
                    <div className="text-center" style={{ width: '49%' }}>
                      <p className="signup-text mb-1" style={{ fontSize: '0.875rem' }}>
                        {' '}
                        <b>Masz już konto?</b>
                      </p>
                      <div className="eg-btn btn--primary p-1 join-btn w-100">
                        <Link
                          to={URL_PATHS.LOGIN}
                          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                          className="eg-btn p-2 join-btn"
                        >
                          Zaloguj się
                        </Link>
                      </div>
                    </div>
                    <div className="text-center" style={{ width: '49%' }}>
                      <p className="signup-text mb-1 " style={{ fontSize: '0.875rem' }}>
                        <b>Jesteś fachowcem?</b>
                      </p>
                      <div className="eg-btn btn--dark p-1 join-btn w-100">
                        <Link
                          to={URL_PATHS.SIGNUP_CONTRACTOR}
                          onClick={() => scrollToTop()}
                          className="eg-btn p-2 join-btn"
                        >
                          Dołącz Tutaj
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <SignupForm
                  register={register}
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmit}
                  errors={errors}
                  isSubmitting={isSubmitting}
                  isContractorForm={false}
                />
                <div className="text-center mt-3" style={{ width: '100%' }}>
                  <p className="signup-text mb-1 " style={{ fontSize: '0.875rem' }}>
                    <b>Jesteś fachowcem?</b>
                  </p>
                  <div className="eg-btn btn--dark p-1 join-btn w-100">
                    <Link
                      to={URL_PATHS.SIGNUP_CONTRACTOR}
                      onClick={() => scrollToTop()}
                      className="sign-here-btn eg-btn p-2 join-btn text-uppercase"
                    >
                      Dołącz Tutaj
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUpClient
