import { useState, useEffect } from 'react'

export const useIsLargeScreen = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.matchMedia('(min-width: 992px)').matches)

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.matchMedia('(min-width: 992px)').matches)
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Call once to set the state immediately

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return isLargeScreen
}
