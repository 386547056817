import React from 'react'
import PropTypes from 'prop-types'
import { MarketingAndNotificationSchema } from './schemas/marketingAndNotification'
import Form from '../../../common/Form'
import { useForm } from 'react-hook-form'
import { useUserActions } from '../../../../_actions/user.actions'
import { useAlerts } from '../../../../context/AlertContext'
import { zodResolver } from '@hookform/resolvers/zod'
import { useUser } from '../../../../context/UserContext'

const MarketingAndNotificationForm = ({ user }) => {
  const { setUser } = useUser()
  const { updateNotificationAndMarketingPreferences } = useUserActions()
  const { addAlert } = useAlerts()
  const isContractor = user.is_contractor

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    control,
    watch,
    setValue,
    resetField,
  } = useForm({
    resolver: zodResolver(MarketingAndNotificationSchema),
  })

  const onSubmit = async (data) => {
    const payload = {
      is_external_marketing_enabled: data.isExternalMarketingEnabled,
      is_marketing_enabled: data.isMarketingEnabled,
      is_notification_enabled: data.notificationEnable,
      is_latest_fuchy_notification_enabled: data.latestFuchyNotificationEnable,
    }
    try {
      await updateNotificationAndMarketingPreferences(payload)
      setUser((prevUser) => ({
        ...prevUser,
        is_marketing_enabled: data.isMarketingEnabled,
        is_external_marketing_enabled: data.isExternalMarketingEnabled,
        is_notification_enabled: data.notificationEnable,
        is_latest_fuchy_notification_enabled: data.latestFuchyNotificationEnable,
      }))
      addAlert('Twoje preferencje zostały zaktualizowane pomyślnie!', 'success')
    } catch (error) {
      addAlert('Wystąpił błąd podczas aktualizacji konta. Spróbuj ponownie później.', 'error')
    }
    reset()
  }

  return (
    <Form
      buttonLabel="Aktualizuj"
      register={register}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      className="eg-btn profile-btn marketing-notification-form"
      isSubmitting={isSubmitting}
    >
      <div className="form-section-title">
        <p>Powiadomienia i Marketing</p>
      </div>
      <div className="col-md-12 text-start">
        <div className="form-inner">
          <div className="form-group">
            <input
              {...register('notificationEnable')}
              type="checkbox"
              id="notificationEnable"
              defaultChecked={user.is_notification_enabled}
            />
            <label htmlFor="notificationEnable">
              <span style={{ textTransform: 'none' }}>Powiadomienia w aplikacji mamfuche.pl</span>
            </label>
          </div>
        </div>
        {errors.notificationEnable && <div className="error-message">{errors.notificationEnable.message}</div>}
      </div>

      <div className={`col-md-12 text-start ${isContractor ? '' : 'd-none'}`}>
        <div className="form-inner">
          <div className="form-group">
            <input
              {...register('latestFuchyNotificationEnable')}
              type="checkbox"
              id="latestFuchyNotificationEnable"
              defaultChecked={user.is_latest_fuchy_notification_enabled}
            />
            <label htmlFor="latestFuchyNotificationEnable">
              <span style={{ textTransform: 'none' }}>Otrzymywanie powiadomień email o nowych fuchach</span>
            </label>
          </div>
        </div>
        {errors.latestFuchyNotificationEnable && (
          <div className="error-message">{errors.latestFuchyNotificationEnable.message}</div>
        )}
      </div>
      <div className="col-md-12 text-start">
        <div className="form-inner">
          <div className="form-group">
            <input
              {...register('isMarketingEnabled')}
              type="checkbox"
              id="isMarketingEnabled"
              defaultChecked={user.is_marketing_enabled}
            />
            <label htmlFor="isMarketingEnabled">
              <span style={{ textTransform: 'none' }}>
                Chcę otrzymywać od mamfuche.pl oferty specjalne oraz inne treści marketingowe dopasowane do mnie
              </span>
            </label>
          </div>
        </div>
        {errors.isMarketingEnabled && <div className="error-message">{errors.isMarketingEnabled.message}</div>}
      </div>
      <div className="col-md-12 text-start">
        <div className="form-inner ">
          <div className="form-group">
            <input
              {...register('isExternalMarketingEnabled')}
              type="checkbox"
              id="isExternalMarketingEnabled"
              defaultChecked={user.is_external_marketing_enabled}
            />
            <label htmlFor="isExternalMarketingEnabled">
              <span style={{ textTransform: 'none' }}>
                Chcę otrzymywać od podmiotów współpracujących z mamfuche.pl oferty specjalne oraz inne treści
                marketingowe dopasowane do mnie
              </span>
            </label>
          </div>
        </div>
        {errors.isExternalMarketingEnabled && (
          <div className="error-message">{errors.isExternalMarketingEnabled.message}</div>
        )}
      </div>
    </Form>
  )
}
export default MarketingAndNotificationForm

MarketingAndNotificationForm.propsTypes = {
  user: PropTypes.object.isRequired,
}
