import React, { useState, useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import axios from 'axios'
import Skeleton from 'react-loading-skeleton'
import { CheckCircle } from '@mui/icons-material'

// Import React FilePond
import pl_PL from 'filepond/locale/pl-pl.js'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'
import FilePondPluginImageEdit from 'filepond-plugin-image-edit'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
// Import FilePond styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css'
import 'filepond/dist/filepond.min.css'

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginImageEdit,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
)

const UploadGallery = ({
  loading,
  initialImages,
  maxFilesQty,
  maxImageSizeMB,
  fileUploadAPIHandler,
  fileDeleteAPIHandler,
  explanationText,
}) => {
  const [files, setFiles] = useState(initialImages)
  const [errorMsg, setErrorMsg] = useState('')
  const [uploadedFilesCount, setUploadedFilesCount] = useState(initialImages.length)
  const [totalFiles, setTotalFiles] = useState(initialImages.length)
  const [isUploading, setIsUploading] = useState(false)
  const allImagesUploaded = uploadedFilesCount === totalFiles && uploadedFilesCount > 0

  const renderSkeleton = () => {
    return (
      <div className="loading-text">
        <div className="text-container-1">
          <Skeleton width={200} height={35} />
        </div>
        <div className="text-container-1">
          <Skeleton width={300} height={35} />
        </div>
      </div>
    )
  }

  useEffect(() => {
    setFiles(initialImages)
    setUploadedFilesCount(initialImages.length)
    setTotalFiles(initialImages.length)
  }, [initialImages.length])

  const handleUpdateFiles = (fileItems) => {
    setFiles(fileItems)
    setUploadedFilesCount(fileItems.filter((file) => file.serverId).length)
    setTotalFiles(fileItems.length)
  }

  const handleAddFile = () => {
    setTotalFiles((prev) => prev + 1)
    setIsUploading(true)
  }

  const handleProcessFile = (error, file) => {
    if (!error) {
      setUploadedFilesCount((prev) => prev + 1)
      if (uploadedFilesCount + 1 === totalFiles) {
        setIsUploading(false)
      }
    }
  }
  const handleRemoveFile = (fileItem) => {
    if (fileItem.serverId) {
      const fileNameParts = fileItem.file.name.split('.')
      const fileId = fileNameParts.slice(0, -1).join('.')
      fileDeleteAPIHandler(fileId)
    }
  }

  return (
    <div className="create-gallery-section">
      <div className="form-section-title">
        <p>
          Dodaj zdjęcia {files.length}/{maxFilesQty}:
        </p>
      </div>
      <div className="explanation-text">{explanationText}</div>
      <span className="text-danger ">{errorMsg}</span>
      <div className="row upload-container">
        <FilePond
          {...pl_PL}
          files={files}
          allowMultiple={true}
          onupdatefiles={handleUpdateFiles}
          onaddfile={handleAddFile}
          onprocessfile={(error, file) => handleProcessFile(error, file)}
          onwarning={(error) => {
            if (error.body === 'Max files') {
              setErrorMsg(`Maksymalnie możesz dodać ${maxFilesQty} zdjęć!`)
            }
          }}
          maxFileSize={`${maxImageSizeMB}MB`}
          labelMaxFileSizeExceeded={`Maksymalny rozmiar pliku to ${maxImageSizeMB}MB`}
          acceptedFileTypes={['image/jpeg', 'image/png']}
          labelIdle='Przeciągnij lub dodaj zdjęcia <span class="filepond--label-action">TUTAJ</span><br/>'
          maxFiles={maxFilesQty}
          name="file"
          allowImageEdit={true}
          storeAsFile={true}
          onremovefile={(error, fileItem) => {
            handleRemoveFile(fileItem)
          }}
          server={{
            process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
              // Creating a CancelToken source
              const sourceToken = axios.CancelToken.source()

              fileUploadAPIHandler(file, fieldName, progress, sourceToken)
                .then((data) => {
                  const uniqueFileId = data.id
                  load(uniqueFileId)
                })
                .catch((err) => {
                  if (axios.isCancel(err)) {
                    console.log('Request canceled', err.message)
                  } else {
                    error(err.message || 'Nie udało się przetworzyć pliku. Spróbuj ponownie później.')
                  }
                })

              // Return the abort method so FilePond can cancel the upload if needed
              return {
                abort: () => {
                  sourceToken.cancel('Operation canceled by the user.')
                  abort()
                },
              }
            },
            revert: (uniqueFileId, load, error) => {
              fileDeleteAPIHandler(uniqueFileId)
                .then((response) => {
                  load()
                })
                .catch((err) => {
                  error(err.message || 'An error occurred')
                })
            },
            load: (source, load, error, progress, abort, headers) => {
              // Provide a way to cancel the request
              const sourceToken = axios.CancelToken.source()
              axios
                .get(source, {
                  responseType: 'blob',
                  onDownloadProgress: (progressEvent) => {
                    // Optional: handle the progress event here
                  },
                  cancelToken: sourceToken.token,
                })
                .then((response) => {
                  load(response.data)
                })
                .catch((err) => {
                  error(err.message)
                })

              return {
                abort: () => {
                  sourceToken.cancel('Operation canceled by the user.')
                },
              }
            },
          }}
        />
      </div>
      {loading ? (
        renderSkeleton()
      ) : (
        <div className="loading-text">
          <div className="text-container-1">
            <p className={`para text-1 ${allImagesUploaded ? 'text-success' : 'text-danger'}`}>
              Załadowano{' '}
              <span className={allImagesUploaded ? 'text-success' : 'text-danger'}>{uploadedFilesCount}</span>/
              <span className={allImagesUploaded ? 'text-success' : 'text-danger'}>{totalFiles}</span> zdjęć
              <span className="icon-success">
                {isUploading && totalFiles !== 0 && uploadedFilesCount !== files.length ? (
                  <CircularProgress
                    size={20}
                    sx={{
                      color: '#f9395f',
                    }}
                  />
                ) : (!isUploading && allImagesUploaded && uploadedFilesCount === files.length) ||
                  (allImagesUploaded && uploadedFilesCount === files.length) ? (
                  <CheckCircle size={20} />
                ) : null}
              </span>
            </p>
          </div>
          <div className="text-container-2">
            <p className="para text-2">
              Poczekaj na załadowanie <span className="text-danger">wszystkich zdjęć</span> przed przejściem do
              następnego kroku!
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

export default UploadGallery
