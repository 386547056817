import React from 'react'
import SimpleBox from '../../common/SimpleBox'
import { MAX_NUMBER_OF_ACTIVE_BIDS } from '../../../_constants/fucha'

const WhyChooseUsContractor = () => {
  return (
    <>
      <div className="choose-us-section pb-120">
        <img alt="images" src={process.env.PUBLIC_URL + '/images/bg/section-bg2.png'} className="section-bg-bottom" />
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-sm-12 col-md-10 col-lg-8 col-xl-7">
              <div className="section-title1">
                <h2 className="mb-4">Dlaczego warto wybrać mamfuche.pl?</h2>
                <p className="para mb-0">
                  <b>mamfuche.pl</b> to nowoczesna platforma dla fachowców z branży budowlanej, mechaników samochodowych
                  oraz projektantów szukających zleceń remontowych, napraw aut oraz projektów wnętrz, ogrodów, mebli i
                  wielu innych usług. <br />
                  <br /> Intuicyjna obsługa pozwala szybko przeglądać i porównywać zlecenia z całej Polski. Dzięki
                  wbudowanemu czatowi masz bezpośredni kontakt z klientami, co ułatwia negocjacje i ustalenie szczegółów
                  zleceń. Jednorazowa opłata zapewnia pełny dostęp do wszystkich zleceń. <br />
                  <br /> Dołącz do <b>mamfuche.pl</b> i zacznij zarabiać już dziś!
                </p>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center g-4">
            <SimpleBox
              title="Dostęp do zleceń w całej Polsce"
              text="Przeglądaj zlecenia budowlane, naprawy samochodowe oraz projekty wnętrz, ogrodów i mebli z różnych regionów Polski!"
              src={process.env.PUBLIC_URL + '/images/icons/poland.svg'}
              icon
              number="01"
            />
            <SimpleBox
              title="Równe szanse na znalezienie zleceń"
              text={`Maksymalnie ${MAX_NUMBER_OF_ACTIVE_BIDS} aktywnych wycen na fachowca. Wyceny wygasają po zakończeniu zlecenia lub ich wycofaniu.`}
              src={process.env.PUBLIC_URL + '/images/icons/multiple.svg'}
              icon
              number="02"
            />
            <SimpleBox
              title="Żadnych ukrytych kosztów"
              text="Jasno określone opłaty bez niespodzianek. Skup się na zdobywaniu zleceń, nie martwiąc się o dodatkowe koszty."
              src={process.env.PUBLIC_URL + '/images/icons/pig-bank.svg'}
              icon
              number="03"
            />
            <SimpleBox
              title="Wygodna komunikacja z klientami"
              text="Bezpośrednia komunikacja z klientami przez wbudowany czat. Negocjuj warunki, zadawaj pytania i łatwo uzgadniaj szczegóły zleceń!"
              src={process.env.PUBLIC_URL + '/images/icons/chat.svg'}
              icon
              number="04"
            />
            <SimpleBox
              title="Profesjonalny profil fachowca"
              text="Stwórz profesjonalny profil, pokaż swoje doświadczenie w budowlance, mechanice lub projektowaniu i zdobywaj zaufanie klientów!"
              src={process.env.PUBLIC_URL + '/images/icons/contractor-profile.svg'}
              icon
              number="05"
            />
            <SimpleBox
              title="Pełen dostęp do platformy"
              text="Przeglądaj zlecenia, składaj wyceny na fuchy zgodne z Twoimi kwalifikacjami i rozwijaj swoją działalność!"
              src={process.env.PUBLIC_URL + '/images/icons/access.svg'}
              icon
              number="06"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default WhyChooseUsContractor
