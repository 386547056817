export const plTranslations = {
  'Add a comment': 'Dodaj komentarz',
  'Add an option': 'Dodaj opcję',
  'Allow access to camera': 'Zezwól na dostęp do kamery',
  'Allow access to microphone': 'Zezwól na dostęp do mikrofonu',
  'Allow comments': 'Zezwól na komentarze',
  'Allow option suggestion': 'Zezwól na sugerowanie opcji',
  'An error has occurred during recording': 'Wystąpił błąd podczas nagrywania',
  'An error has occurred during the recording processing': 'Wystąpił błąd podczas przetwarzania nagrania',
  Anonymous: 'Anonimowy',
  'Anonymous poll': 'Anonimowa ankieta',
  'Ask a question': 'Zadaj pytanie',
  'Attach files': 'Załącz pliki',
  Cancel: 'Anuluj',
  'Cannot seek in the recording': 'Nie można przewijać nagrania',
  'Channel Missing': 'Brak kanału',
  Close: 'Zamknij',
  'Close emoji picker': 'Zamknij wybierak emotikon',
  'Commands matching': 'Pasujące polecenia',
  'Connection failure, reconnecting now...': 'Błąd połączenia, ponowne łączenie...',
  Create: 'Utwórz',
  'Create poll': 'Utwórz ankietę',
  Delete: 'Usuń',
  Delivered: 'Dostarczono',
  'Download attachment {{ name }}': 'Pobierz załącznik {{ name }}',
  'Drag your files here': 'Przeciągnij tutaj swoje pliki',
  'Drag your files here to add to your post': 'Przeciągnij tutaj swoje pliki, aby dodać do posta',
  'Edit Message': 'Edytuj wiadomość',
  'Edit message request failed': 'Nie udało się edytować wiadomości',
  Edited: 'Edytowano',
  'Emoji matching': 'Pasujące emotikony',
  'Empty message...': 'Pusta wiadomość...',
  End: 'Koniec',
  'End vote': 'Zakończ głosowanie',
  'Error adding flag': 'Błąd dodawania flagi',
  'Error connecting to chat, refresh the page to try again.':
    'Błąd połączenia z czatem, odśwież stronę, aby spróbować ponownie.',
  'Error deleting message': 'Błąd usuwania wiadomości',
  'Error fetching reactions': 'Błąd ładowania reakcji',
  'Error marking message unread. Cannot mark unread messages older than the newest 100 channel messages.':
    'Błąd oznaczania wiadomości jako nieprzeczytanej. Nie można oznaczyć wiadomości starszych niż 100 najnowszych wiadomości na kanale.',
  'Error muting a user ...': 'Błąd wyciszania użytkownika ...',
  'Error pinning message': 'Błąd przypinania wiadomości',
  'Error removing message pin': 'Błąd usuwania przypięcia wiadomości',
  'Error reproducing the recording': 'Błąd odtwarzania nagrania',
  'Error starting recording': 'Błąd rozpoczynania nagrywania',
  'Error unmuting a user ...': 'Błąd wyłączania wyciszenia użytkownika ...',
  'Error uploading attachment': 'Błąd przesyłania załącznika',
  'Error uploading file': 'Błąd przesyłania pliku',
  'Error uploading image': 'Błąd przesyłania obrazu',
  'Error · Unsent': 'Błąd · Niewysłane',
  'Error: {{ errorMessage }}': 'Błąd: {{ errorMessage }}',
  'Sorry, this channel has been frozen by the admin': 'Przepraszamy, ten kanał został zamrożony przez administratora',
  'Failed to jump to the first unread message': 'Nie udało się przejść do pierwszej nieprzeczytanej wiadomości',
  'Failed to mark channel as read': 'Nie udało się oznaczyć kanału jako przeczytanego',
  'Failed to play the recording': 'Nie udało się odtworzyć nagrania',
  File: 'Plik',
  'File is too large: {{ size }}, maximum upload size is {{ limit }}':
    'Plik jest za duży: {{ size }}, maksymalny rozmiar przesyłania to {{ limit }}',
  Flag: 'Flaga',
  'Latest Messages': 'Najnowsze wiadomości',
  'Load more': 'Załaduj więcej',
  'Mark as unread': 'Oznacz jako nieprzeczytane',
  'Maximum number of votes (from 2 to 10)': 'Maksymalna liczba głosów (od 2 do 10)',
  Menu: 'Menu',
  'Message Failed · Click to try again': 'Wiadomość nieudana · Kliknij, aby spróbować ponownie',
  'Message Failed · Unauthorized': 'Wiadomość nieudana · Nieautoryzowana',
  'Message deleted': 'Wiadomość usunięta',
  'Message has been successfully flagged': 'Wiadomość została pomyślnie oznaczona',
  'Message pinned': 'Wiadomość przypięta',
  'Messages have been marked unread.': 'Wiadomości zostały oznaczone jako nieprzeczytane.',
  'Missing permissions to upload the attachment': 'Brak uprawnień do przesyłania załącznika',
  'Multiple answers': 'Wiele odpowiedzi',
  Mute: 'Wycisz',
  New: 'Nowe',
  'New Messages!': 'Nowe wiadomości!',
  'No chats here yet…': 'Nie ma tu jeszcze wiadomości…',
  'No results found': 'Nie znaleziono wyników',
  'Nobody will be able to vote in this poll anymore.': 'Nikt nie będzie już mógł głosować w tej ankiecie.',
  'Nothing yet...': 'Jeszcze nic...',
  Ok: 'Ok',
  'Open emoji picker': 'Otwórz wybierak emotikon',
  'Option already exists': 'Opcja już istnieje',
  Options: 'Opcje',
  'People matching': 'Pasujący ludzie',
  Pin: 'Przypnij',
  'Pinned by': 'Przypięte przez',
  Poll: 'Ankieta',
  'Poll comments': 'Komentarze do ankiety',
  'Poll options': 'Opcje ankiety',
  'Poll results': 'Wyniki ankiety',
  Question: 'Pytanie',
  Quote: 'Cytat',
  'Recording format is not supported and cannot be reproduced':
    'Format nagrania nie jest obsługiwany i nie można go odtworzyć',
  Reply: 'Odpowiedz',
  'Reply to Message': 'Odpowiedz na wiadomość',
  Search: 'Szukaj',
  'Searching...': 'Wyszukiwanie...',
  'See all options ({{count}})_one': 'Zobacz wszystkie opcje ({{count}})',
  'See all options ({{count}})_other': 'Zobacz wszystkie opcje ({{count}})',
  'Select one': 'Wybierz jedną',
  'Select one or more': 'Wybierz jedną lub więcej',
  'Select up to {{count}}_one': 'Wybierz do {{count}}',
  'Select up to {{count}}_other': 'Wybierz do {{count}}',
  Send: 'Wyślij',
  'Send Anyway': 'Wyślij mimo to',
  'Send message request failed': 'Nie udało się wysłać wiadomości',
  'Sending...': 'Wysyłanie...',
  'Show all': 'Pokaż wszystkie',
  Shuffle: 'Przetasuj',
  'Slow Mode ON': 'Tryb wolny WŁĄCZONY',
  'Some of the files will not be accepted': 'Niektóre pliki nie zostaną zaakceptowane',
  Submit: 'Zatwierdź',
  'Suggest an option': 'Zaproponuj opcję',
  'This message did not meet our content guidelines': 'Ta wiadomość nie spełnia naszych wytycznych dotyczących treści',
  'This message was deleted...': 'Ta wiadomość została usunięta...',
  Thread: 'Wątek',
  'To start recording, allow the camera access in your browser':
    'Aby rozpocząć nagrywanie, zezwól na dostęp do kamery w przeglądarce',
  'To start recording, allow the microphone access in your browser':
    'Aby rozpocząć nagrywanie, zezwól na dostęp do mikrofonu w przeglądarce',
  'Type a number from 2 to 10': 'Wpisz liczbę od 2 do 10',
  'Type your message': 'Wpisz swoją wiadomość',
  Unmute: 'Wyłącz wyciszenie',
  Unpin: 'Odepnij',
  'Unread messages': 'Nieprzeczytane wiadomości',
  'Unsupported attachment': 'Nieobsługiwany załącznik',
  'Update your comment': 'Zaktualizuj swój komentarz',
  'Upload type: "{{ type }}" is not allowed': 'Typ przesyłania: "{{ type }}" nie jest dozwolony',
  'User uploaded content': 'Użytkownik przesłał treść',
  'View results': 'Zobacz wyniki',
  'View {{count}} comments_one': 'Zobacz {{count}} komentarz',
  'View {{count}} comments_other': 'Zobacz {{count}} komentarzy',
  'Voice message': 'Wiadomość głosowa',
  'Vote ended': 'Głosowanie zakończone',
  'Wait until all attachments have uploaded': 'Poczekaj, aż wszystkie załączniki zostaną przesłane',
  You: 'Ty',
  'You have no channels currently': 'Obecnie nie masz żadnych kanałów',
  "You've reached the maximum number of files": 'Osiągnąłeś maksymalną liczbę plików',
  'aria/Attachment': 'Załącznik',
  'aria/Cancel Reply': 'Anuluj odpowiedź',
  'aria/Cancel upload': 'Anuluj przesyłanie',
  'aria/Channel list': 'Lista kanałów',
  'aria/Channel search results': 'Wyniki wyszukiwania kanałów',
  'aria/Close thread': 'Zamknij wątek',
  'aria/Download attachment': 'Pobierz załącznik',
  'aria/Emoji picker': 'Wybierak emotikon',
  'aria/File input': 'Wprowadzanie pliku',
  'aria/File upload': 'Przesyłanie pliku',
  'aria/Image input': 'Wprowadzanie obrazu',
  'aria/Load More Channels': 'Załaduj więcej kanałów',
  'aria/Menu': 'Menu',
  'aria/Message Options': 'Opcje wiadomości',
  'aria/Open Attachment Selector': 'Otwórz wybierak załączników',
  'aria/Open Message Actions Menu': 'Otwórz menu akcji wiadomości',
  'aria/Open Reaction Selector': 'Otwórz wybierak reakcji',
  'aria/Open Thread': 'Otwórz wątek',
  'aria/Reaction list': 'Lista reakcji',
  'aria/Remove attachment': 'Usuń załącznik',
  'aria/Retry upload': 'Ponów przesyłanie',
  'aria/Send': 'Wyślij',
  live: 'na żywo',
  replyCount_one: '1 odpowiedź',
  replyCount_other: '{{ count }} odpowiedzi',
  searchResultsCount_one: '1 wynik',
  searchResultsCount_other: '{{ count }} wyników',
  'this content could not be displayed': 'tej treści nie można wyświetlić',
  'timestamp/DateSeparator': '{{ timestamp | timestampFormatter(format: DD/MM/YYYY [o] HH:mm:ss) }}',
  'timestamp/MessageTimestamp': '{{ timestamp | timestampFormatter(format: DD/MM/YYYY [o] HH:mm:ss) }}',
  // 'timestamp/MessageTimestamp': '{{ timestamp | timestampFormatter(calendar: true; calendarFormats: {"lastDay": "[Wczoraj o] LT", "lastWeek": "[Tydzień temu] dddd [o] LT", "nextDay": "[Jutro o] LT", "nextWeek": "dddd [o] LT", "sameDay": "[Dzisiaj o] LT", "sameElse": "L"}) }}',
  'timestamp/PollVote': '{{ timestamp | timestampFormatter(format: DD/MM/YYYY [o] HH:mm:ss)  }}',
  'timestamp/PollVoteTooltip': '{{ timestamp | timestampFormatter(format: DD/MM/YYYY [o] HH:mm:ss) }}',
  'timestamp/SystemMessage': '{{ timestamp | timestampFormatter(format: DD/MM/YYYY [o] HH:mm:ss) }}',
  unreadMessagesSeparatorText_one: '1 nieprzeczytana wiadomość',
  unreadMessagesSeparatorText_other: '{{count}} nieprzeczytanych wiadomości',
  '{{ commaSeparatedUsers }} and {{ moreCount }} more': '{{ commaSeparatedUsers }} i {{ moreCount }} więcej',
  '{{ commaSeparatedUsers }}, and {{ lastUser }}': '{{ commaSeparatedUsers }}, i {{ lastUser }}',
  '{{ firstUser }} and {{ secondUser }}': '{{ firstUser }} i {{ secondUser }}',
  '{{ imageCount }} more': '{{ imageCount }} więcej',
  '{{ memberCount }} members': '{{ memberCount }} użytkowników',
  '{{ user }} has been muted': '{{ user }} został wyciszony',
  '{{ user }} has been unmuted': '{{ user }} został odciszony',
  '{{ user }} is typing...': '{{ user }} pisze...',
  '{{ users }} and more are typing...': '{{ users }} i więcej pisze...',
  '{{ users }} and {{ user }} are typing...': '{{ users }} i {{ user }} piszą...',
  '{{ watcherCount }} online': '{{ watcherCount }} online',
  '{{count}} unread_one': '{{count}} nieprzeczytana',
  '{{count}} unread_other': '{{count}} nieprzeczytanych',
  '{{count}} votes_one': '{{count}} głos',
  '{{count}} votes_other': '{{count}} głosów',
  '🏙 Attachment...': '🏙 Załącznik...',
  '📊 {{createdBy}} created: {{ pollName}}': '📊 {{createdBy}} utworzył: {{ pollName}}',
  '📊 {{votedBy}} voted: {{pollOptionText}}': '📊 {{votedBy}} zagłosował: {{pollOptionText}}',
}
