export const CONTACT_SUBJECTS = [
  'Ogólne zapytanie',
  'Zapytanie o możliwość współpracy',
  'Zapytanie o pracę',
  'Zapytanie o płatność',
  'Zgłoś fachowca',
  'Zgłoś fuchę',
  'Zgłoś zleceniodawcę',
  'Mam problem techniczny',
  'Opinia',
  'Inne',
]
