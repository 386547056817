import { useApi } from '../_helpers'
import { DYNAMIC_API_URLS, API_URLS } from '../_constants'

export const useContractorActions = () => {
  const api = useApi()

  return {
    createClientReview,
    deleteBid,
    getContractorActiveBidsCount,
    getContractorDashboardData,
    getFuchaMessages,
    getGallery,
    getMyBids,
    getMyFuchy,
    getPublicProfile,
    getContractorReviewsSummary,
    updateProfile,
    uploadGalleryImage,
  }

  async function createClientReview(payload) {
    const response = await api.post(API_URLS.CONTRACTOR_CREATE_REVIEW, payload)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function deleteBid(bidId) {
    const response = await api.delete(DYNAMIC_API_URLS.DeleteBid(bidId))
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function getContractorDashboardData() {
    const response = await api.get(API_URLS.CONTRACTOR_DASHBOARD_DATA)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function getGallery(contractorId) {
    const response = await api.get(DYNAMIC_API_URLS.ContractorPublicGallery(contractorId))
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function getFuchaMessages(fuchaId) {
    const response = await api.get(DYNAMIC_API_URLS.GetFuchaMessages(fuchaId))
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function getMyBids(fuchaId) {
    const response = await api.get(DYNAMIC_API_URLS.GetMyBids(fuchaId))
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function getMyFuchy(page, filter) {
    const response = await api.get(`${API_URLS.CONTRACTOR_MY_FUCHY}?page=${page}&type=${filter}`)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function getPublicProfile(contractorId) {
    const response = await api.get(DYNAMIC_API_URLS.ContractorPublicProfile(contractorId))
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function updateProfile(payload) {
    const response = await api.put(API_URLS.CONTRACTOR_UPDATE_PROFILE, payload)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function getContractorReviewsSummary(contractorId) {
    const response = await api.get(DYNAMIC_API_URLS.GetContractorReviewsSummary(contractorId))
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function getContractorActiveBidsCount() {
    const response = await api.get(API_URLS.CONTRACTOR_ACTIVE_BIDS_COUNT)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function uploadGalleryImage(file, fieldName, progress, sourceToken) {
    const formData = new FormData()
    formData.append(fieldName, file, file.name)
    const response = await api.post(API_URLS.CONTRACTOR_GALLERY, formData, {
      onUploadProgress: (e) => {
        progress(e.lengthComputable, e.loaded, e.total)
      },
      cancelToken: sourceToken.token,
    })
    if (response.statusCode >= 200 && response.statusCode < 300) {
      console.log('Image uploaded successfully')
      return response.data
    }
    return Promise.reject(response)
  }
}
