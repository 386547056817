import React from 'react'
import { Link } from 'react-router-dom'
import FuchaIcon from '../../../assets/images/icons/animation-gif2.gif'
import { URL_PATHS } from '../../../_constants'
import { scrollToTop } from '../../../_helpers'

const WhoWeAreArea = () => {
  return (
    <>
      <div className="about-section pt-120 pb-120 overflow-hidden">
        <img
          src={process.env.PUBLIC_URL + '/images/bg/section-bg2.png'}
          className="img-fluid section-bg-top"
          alt="section-bg"
        />
        <div className="container">
          <div className="row d-flex justify-content-center g-4">
            <div className="col-xxl-6 col-lg-10 col-md-10">
              <div className="about-img-area">
                <div className="total-tag">
                  <img className="d-xl-block d-lg-none wow fadeInUp" src={FuchaIcon} alt="images" />
                </div>
                <img
                  src={process.env.PUBLIC_URL + '/images/bg/about-img.png'}
                  className="img-fluid about-img wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay=".2s"
                  alt="about-img"
                />
                <img
                  src={process.env.PUBLIC_URL + '/images/bg/about-linear.png'}
                  className="img-fluid about-linear"
                  alt=""
                />
                <img
                  src={process.env.PUBLIC_URL + '/images/bg/about-vector.png'}
                  className="img-fluid about-vector"
                  alt=""
                />
              </div>
            </div>
            <div className="about-content-container col-xxl-6 col-lg-10 col-md-10">
              {/* BIG DEVICES */}
              <div className="about-content wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
                <p className="header-text">Kim Jesteśmy</p>
                <h2>
                  <span className="logo-text">mamfuche.pl </span> - wyjątkowe miejsce gdzie to fuchy szukają swoich
                  wykonawców!
                </h2>
                <p className="para">
                  <b>mamfuche.pl</b> to platforma dla fachowców z branży budowlanej, mechaniki samochodowej oraz usług
                  projektowych – w tym projektów wnętrz, ogrodów, mebli, budowlanych i technicznych. Łączymy klientów
                  poszukujących remontów, napraw aut oraz projektów z doświadczonymi wykonawcami w całej Polsce!
                </p>
                <p className="para">
                  Potrzebujesz remontu, budowy domu, malowania ścian, układania płytek, naprawy samochodu lub projektu
                  wnętrza czy ogrodu? Dodaj ogłoszenie na <b>mamfuche.pl</b> i znajdź najlepszego specjalistę!
                </p>
                <p className="para">
                  Jesteś wykonawcą w budowlance, mechanikiem samochodowym lub projektantem? Dołącz do <b>mamfuche.pl</b>{' '}
                  i zdobywaj zlecenia – od drobnych napraw po duże realizacje!
                </p>
                <Link className="eg-btn btn--primary btn--md mt-2" to={URL_PATHS.SIGNUP} onClick={() => scrollToTop()}>
                  Zarejestruj konto
                </Link>
              </div>

              {/* SMALL DEVICES */}
              <div className="small-devices-about-content wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
                <img className="small-devices-image" src={FuchaIcon} alt="images" />
                <div>
                  <p className="header-text">Kim Jesteśmy</p>
                  <h2>
                    <span className="logo-text">mamfuche.pl </span> wyjątkowe miejsce gdzie to fuchy szukają swoich
                    wykonawców!
                  </h2>
                  <p className="para">
                    <b>mamfuche.pl</b> to platforma dla fachowców z branży budowlanej, mechaniki samochodowej oraz usług
                    projektowych – w tym projektów wnętrz, ogrodów, mebli, budowlanych i technicznych. Łączymy klientów
                    poszukujących remontów, napraw aut oraz projektów z doświadczonymi wykonawcami w całej Polsce!
                  </p>
                  <p className="para">
                    Potrzebujesz remontu, budowy domu, malowania ścian, układania płytek, naprawy samochodu lub projektu
                    wnętrza czy ogrodu? Dodaj ogłoszenie na <b>mamfuche.pl</b> i znajdź najlepszego specjalistę!
                  </p>
                  <p className="para">
                    Jesteś wykonawcą w budowlance, mechanikiem samochodowym lub projektantem? Dołącz do{' '}
                    <b>mamfuche.pl</b> i zdobywaj zlecenia – od drobnych napraw po duże realizacje!
                  </p>
                  <Link
                    className="eg-btn btn--primary btn--md mt-2"
                    to={URL_PATHS.SIGNUP}
                    onClick={() => scrollToTop()}
                  >
                    Zarejestruj konto
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WhoWeAreArea
