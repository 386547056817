import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { getFormattedDate } from '../../../../_helpers'

const FuchaDetailCard = ({ fucha, fuchaLoading, disabledFuchaAction }) => {
  const renderSkeleton = () => (
    <div className="col-md-12">
      <div className="card ">
        <div className="card-body gallery-container ">
          <div className="sidebar-widget-title">
            <h4>Szczegółowy Opis Fuchy</h4>
            <span />
          </div>
          <div className="describe-content">
            <h5 className="mt-4">
              <Skeleton width={100} />
            </h5>
            <p className="para">
              <Skeleton width={250} />
            </p>
            <h5 className="mt-4">
              <Skeleton width={100} />
            </h5>
            <p className="para">
              <Skeleton width={250} />
            </p>
            <h5>
              <Skeleton width={100} />
            </h5>
            <p className="para">
              <Skeleton width={250} />
            </p>
            <h5>
              <Skeleton width={100} />
            </h5>
            <p className="para">
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </p>
          </div>
        </div>
      </div>
    </div>
  )

  return fuchaLoading && !fucha ? (
    renderSkeleton()
  ) : (
    <div className="col-md-12">
      <div className={`card ${disabledFuchaAction ? 'expired-fucha' : ''}`}>
        <div className="card-body gallery-container ">
          <div className="sidebar-widget-title">
            <h4>Szczegółowy Opis Fuchy</h4>
            <span />
          </div>
          <div className="describe-content">
            <h5 className="mt-4">Tytuł:</h5>
            <p className="para">{fucha.title}</p>
            <h5 className="mt-4">Lokalizacja:</h5>
            <p className="para">
              {fucha.province} - {fucha.county}
            </p>
            <h5>Termin wykonania:</h5>
            {fucha.is_realization_asap ? (
              <p className="para">Jak najszybciej</p>
            ) : !fucha.realization_date_from && !fucha.realization_date_to ? (
              <p className="para">Dostosuje się do fachowca</p>
            ) : (
              <div className="d-flex">
                <p className="para" style={{ marginRight: '20px' }}>
                  OD: {fucha.realization_date_from}
                </p>
                <p className="para">DO: {fucha.realization_date_to}</p>
              </div>
            )}

            <h5>Opis:</h5>
            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} className="para">
              {fucha.description}
            </p>
            <h5>Data wystawienia:</h5>
            <p className="para">{getFormattedDate(fucha.created_at)}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FuchaDetailCard
