import React, { useEffect } from 'react'

const PageOne = ({ register, setValue, watch, errors }) => {
  const activityType = watch('businessType')
  const disableEnableCompanyName = activityType === 'brak' || activityType === undefined ? true : false

  const handleChange = (e, value) => {
    setValue(value, e.target.value)
  }

  useEffect(() => {
    if (disableEnableCompanyName) {
      setValue('companyName', '')
      setValue('companyTaxId', '')
    }
  }, [activityType, setValue, disableEnableCompanyName])

  return (
    <>
      <p>Czy masz zarejestrowaną działalność gospodarczą?</p>
      <div className="row">
        <div className="col-xl-6 col-lg-12 col-md-6">
          <div className="inputGroup">
            <input id="noCompany" type="radio" {...register('businessType')} value="brak" />
            <label htmlFor="noCompany">Nie prowadzę działalności</label>
          </div>
        </div>
        <div className="col-xl-6 col-lg-12 col-md-6">
          <div className="inputGroup">
            <input id="onePersonCompany" type="radio" {...register('businessType')} value="działalność jednoosobowa" />
            <label htmlFor="onePersonCompany">Działalność jednoosobowa</label>
          </div>
        </div>
        <div className="col-xl-6 col-lg-12 col-md-6">
          <div className="inputGroup">
            <input id="isCompany" type="radio" {...register('businessType')} value="firma" />
            <label htmlFor="isCompany">Firma</label>
          </div>
        </div>

        <div className="col-xl-6 col-lg-12 col-md-6">
          <div style={{ position: 'relative' }} className="form-inner company-name-container">
            <input
              disabled={disableEnableCompanyName}
              {...register('companyName')}
              className="company-name-input"
              onChange={(e) => {
                handleChange(e, 'companyName')
              }}
              type="text"
              placeholder="Nazwa Firmy"
              maxLength={100}
            />
            {errors.companyName && <div className="error-message">{errors.companyName.message}</div>}
          </div>
        </div>

        <div className="col-xl-12">
          <div style={{ position: 'relative' }} className="form-inner company-tax-id-container">
            <input
              disabled={disableEnableCompanyName}
              {...register('companyTaxId')}
              className="company-tax-id-input"
              onChange={(e) => {
                handleChange(e, 'companyTaxId')
              }}
              type="text"
              placeholder="Podaj swój NIP"
              maxLength={10}
            />
            {errors.companyTaxId && <div className="tax-id-error-message">{errors.companyTaxId.message}</div>}
          </div>
        </div>
      </div>
    </>
  )
}

export default PageOne
