import { z } from 'zod'
import { BID_RATE_TYPE } from '../../../_constants/values'

export const SendBidSchema = z.object({
  bid: z.coerce
    .number()
    .int('Oferta musi być liczbą')
    .gte(1, 'Minimalna wartość oferty to 1zł')
    .lte(9999999, 'Maksymalna wartość oferty to 9999999zł'),
})
