import React, { useState, useEffect } from 'react'

import { useApi } from '../../../../../_helpers'
import { MAX_IMAGE_SIZE_MB, DYNAMIC_API_URLS } from '../../../../../_constants'
import { useFuchaActions } from '../../../../../_actions'
import UploadGallery from '../../../../../components/common/UploadGallery'

const PageTwo = ({ fuchaId }) => {
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(true)
  const { api } = useApi()
  const { getFuchaImages, uploadFuchaImage } = useFuchaActions()
  const MAX_NUMBER_OF_IMAGES = 8
  const EXPLANATION_MESSAGE = (
    <p>
      Przeciągnij lub dodaj zdjęcia z folderu. Staraj się dodawać zdjęcia, które ułatwią ocenę dla fachowców.
      <br></br> Pamiętaj, że pierwsze załadowane zdjęcie będzie używane jako miniatura dla ogłoszenia.
    </p>
  )

  useEffect(() => {
    getFuchaImages(fuchaId)
      .then((resp) => {
        const initUserGallery = resp.map((item) => {
          return {
            source: `${item.url}`,
            options: {
              type: 'local',
              serverId: item.id,
            },
            serverId: item.id,
          }
        })
        setFiles(initUserGallery)

        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }, [])

  const fileUploadAPIHandler = async (file, fieldName, progress, sourceToken) => {
    return await uploadFuchaImage(fuchaId, file, fieldName, progress, sourceToken)
  }

  const fileDeleteAPIHandler = async (fileId) => {
    api.delete(DYNAMIC_API_URLS.FuchaDeleteImage(fuchaId, fileId), {}).catch((err) => {
      console.error('Error removing file:', err)
      if (err.response && err.response.status === 404) {
        console.warn('File not found on server. It may have already been removed.')
      }
    })
  }

  return (
    <div className="create-fucha-gallery-section">
      <UploadGallery
        loading={loading}
        initialImages={files}
        maxFilesQty={MAX_NUMBER_OF_IMAGES}
        maxImageSizeMB={MAX_IMAGE_SIZE_MB}
        fileUploadAPIHandler={fileUploadAPIHandler}
        fileDeleteAPIHandler={fileDeleteAPIHandler}
        explanationText={EXPLANATION_MESSAGE}
      />
    </div>
  )
}

export default PageTwo
