import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'
import { URL_PATHS } from '../../../../_constants/urls'
import { useDashboardTab } from '../../../../context/DashboardTabContext'
import { scrollToTop } from '../../../../_helpers'

const DisabledFuchaCard = ({ loadingBids, scrollToOffer, isFuchaOwner }) => {
  const { activeTab, setActiveTab } = useDashboardTab()
  const renderSkeleton = () => {
    return (
      <div className="form-title">
        <>
          <h5>
            <Skeleton width={100} />
          </h5>
          <h5>
            <Skeleton width={100} />
          </h5>
          <h5>
            <Skeleton width={100} />
          </h5>
          <button className="eg-btn btn--primary p-2 m-3 join-btn" onClick={scrollToOffer}>
            Sprawdź Wyceny
          </button>
        </>
      </div>
    )
  }

  return (
    <div className="card mb-3">
      <div className="card-body">
        <div className="sidebar-widget-title">
          <h4>Niestety ta fucha nie jest już aktywna</h4>
          <span />
        </div>
        <div className="product-details-right wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
          <div className="bid-form">
            {loadingBids ? (
              renderSkeleton()
            ) : (
              <div className="form-title">
                {isFuchaOwner ? (
                  <p>
                    Aktualnie nie możesz już akceptować żadnych wycen na tę fuchę. Jeżeli nadal potrzebujesz fachowca do
                    tej fuchy wystaw ją ponownie.
                  </p>
                ) : (
                  <p>
                    Wygląda na to, że ta fucha wygasła. Ogłoszenie zostało zakończone lub usunięte przez właściciela.
                    Zachęcamy do przeglądania innych dostępnych fuch.
                  </p>
                )}
                {isFuchaOwner ? (
                  <Link
                    style={{ width: '165px', padding: '5px 12px', margin: '20px 0' }}
                    className="eg-btn btn--primary header-btn"
                    to={`${URL_PATHS.DASHBOARD}`}
                    onClick={() => {
                      setActiveTab('tab21')
                      scrollToTop()
                    }}
                  >
                    Wystaw Fuchę
                  </Link>
                ) : (
                  <Link
                    style={{ width: '165px', padding: '5px 12px', margin: '20px 0' }}
                    className="eg-btn btn--primary header-btn"
                    to={URL_PATHS.FUCHA_LISTING}
                  >
                    Przeglądaj Fuchy
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DisabledFuchaCard
