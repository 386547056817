import React from 'react'
import { Link } from 'react-router-dom'
import { URL_PATHS } from '../../../_constants/urls'

const HowItWorkContractorContent = () => {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })
  return (
    <>
      <div className="how-work-section pt-120 pb-120">
        <img alt="images" src={process.env.PUBLIC_URL + '/images/bg/section-bg2.png'} className="section-bg-top" />
        <div className="container">
          <div className="row g-4 mb-60">
            <div className="how-work-title">
              <h3>
                Szukasz zleceń budowlanych, napraw samochodowych lub projektów wnętrz, ogrodów czy mebli? mamfuche.pl to
                idealne miejsce dla fachowców takich jak Ty!
              </h3>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="how-work-content wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s">
                <span>01.</span>
                <h3>Rejestracja i zakładanie konta</h3>
                <p className="para">
                  Wejdź na mamfuche.pl i kliknij przycisk "Dołącz jako fachowiec" lub kliknij przycisk poniżej. Wypełnij
                  formularz rejestracyjny, podając swoje podstawowe dane, takie jak imię, adres e-mail oraz hasło. Po
                  zakończeniu rejestracji otrzymasz e-mail z potwierdzeniem, który umożliwi aktywację Twojego konta.
                  Sprawdź swoj email i aktywuj konto.
                </p>
                <Link to={URL_PATHS.SIGNUP_CONTRACTOR} onClick={scrollTop} className="eg-btn btn--primary btn--md">
                  Załóż konto
                </Link>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 d-flex justify-content-lg-end justify-content-center">
              <div className="how-work-img wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
                <img alt="images" src={process.env.PUBLIC_URL + '/images/bg/create-account.svg'} className="work-img" />
              </div>
            </div>
          </div>
          <div className="row g-4 mb-60">
            <div className="col-xl-6 col-lg-6 d-flex justify-content-lg-start justify-content-center order-lg-1 order-2">
              <div className="how-work-img wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
                <img alt="images" src={process.env.PUBLIC_URL + '/images/bg/contact.svg'} className="work-img" />
              </div>
            </div>
            <div className="row g-4">
              <div className="col-xl-6 col-lg-6">
                <div className="how-work-content wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s">
                  <span>02.</span>
                  <h3>Wyszukiwanie fuch i składanie wycen</h3>
                  <p className="para">
                    Gdy twoje konto będzie już aktywne, możesz rozpocząć przeglądanie i wyszukiwanie fuch. Skorzystaj z
                    intuicyjnej wyszukiwarki, filtrów oraz kategorii, aby łatwo znaleźć interesujące Cię fuchy. Złóż
                    pierwszą wycenę aby odblokować funkcję czatu. Twoja wycena będzie widoczna jedynie dla zlecającego.
                    <b>
                      {' '}
                      Pamiętaj! Możesz brać udzial w maksymalnie 20 fuchach jednocześnie! Ta liczba zmniejsza się
                      jedynie po wycofaniu wyceny lub zakończeniu zlecenia, więc rozważnie wysyłaj wyceny.
                    </b>{' '}
                    Aktualną liczbę aktywnych wycen znajdziesz na swoim profilu ("Aktywne Fuchy")
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 d-flex justify-content-lg-end justify-content-center">
                <div className="how-work-img wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
                  <img alt="images" src={process.env.PUBLIC_URL + '/images/bg/search-fuchy.svg'} className="work-img" />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 order-lg-2 order-1">
              <div className="how-work-content wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s">
                <span>03.</span>
                <h3>Skontaktuj się ze zleceniodawcą</h3>
                <p className="para">
                  Jeśli masz dodatkowe pytania dotyczące fuchy lub potrzebujesz więcej szczegółów, aby przygotować
                  precyzyjną wycenę, skontaktuj się bezpośrednio ze zleceniodawcą za pomocą naszego wygodnego czatu.
                  Ustal szczegóły i w razie potrzeby wyślij drugą wycenę. Pamiętaj, że ostateczna decyzja kto zostanie
                  wybrany należy do zleceniodawcy.
                </p>
              </div>
            </div>
          </div>

          {/* Add YouTube Video Section */}
          <div className="row g-4 video-container">
            <div className="col-12">
              <div className="video-box">
                <iframe
                  width="100%"
                  height="600"
                  src="https://www.youtube.com/embed/PXDQurqSyWo"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HowItWorkContractorContent
