import React from 'react'
import Skeleton from 'react-loading-skeleton'

const TextSkeletonCard = () => {
  return (
    <div className="card mb-3">
      <div className="card-body">
        <h4>
          <Skeleton width={250} />
        </h4>

        <div className="sidebar-widget-title">
          <span />
        </div>
        <div className="product-details-right  wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
          <div className="bid-form">
            <div className="form-title">
              <Skeleton width={200} />
              <br></br>
              <Skeleton width={250} />
              <Skeleton width={250} />
              <Skeleton width={250} />
              <button className="eg-btn m-3 p-2">
                <Skeleton width={70} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TextSkeletonCard
