import React from 'react'
import * as Sentry from '@sentry/react'
import { BrowserRouter, Route, Switch, Router } from 'react-router-dom'
import DocumentMeta from 'react-document-meta'
import Footer from './common/Footer'
import HomePage from './page/homepage/HomePage'
import Header from './common/Header'
import BottomNav from './common/BottomNav'
import Contact from './page/contact/Contact'
import PricesPage from './page/pricesPage/PricesPage'
import PrivacyPolicyContent from './page/privacyPolicy/PrivacyPolicyContent.jsx'
import TermsAndConditions from './page/terms/TermsAndConditions'
import ErrorPage from './page/error/ErrorPage'
import SignUpClient from './page/signUp/SignUpClient'
import SignUpContractor from './page/signUp/SingnUpContractor'
import Faq from './page/faq/Faq'
import PrivateRoute from './common/PrivateRoute'
import '../index.css'
import Login from './page/login/Login.jsx'
import passwordReset from './page/passwordReset/passwordReset.jsx'
import PasswordUpdate from './page/passwordReset/passwordUpdate.jsx'
import FuchaDetails from './page/fuchaDetails/FuchaDetails.jsx'
import Dashboard from './page/dashboard/Dashboard'
import InfoAfterSignup from './page/signUp/InfoAfterSignup.jsx'
import Blog from './page/blog/Blog'
import Chat from './page/chat/Chat.jsx'
import BlogDetails from './page/BlogDetails/BlogDetails'
import FuchaListing from './page/fuchaListing/FuchaListing.jsx'
import HowItWork from './page/howItWork/HowItWork'
import About from './page/about/About'
import ContractorProfilePage from './page/contractorProfilePage/ContractorProfilePage'
import Alerts from './common/Alerts'
import useCookieScript from 'use-cookiescript-hook'
import { AlertProvider } from '../context/AlertContext'
import { AuthProvider } from '../context/AuthContext'
import { DashboardTabProvider } from '../context/DashboardTabContext.js'
import { NotificationsAndMessagesProvider } from '../context/NotificationsAndMessagesContext.js'
import { URL_PATHS, DYNAMIC_URL_PATHS } from '../_constants/urls.js'
import { history } from '../_helpers/history.js'

if (process.env.REACT_APP_SENTRY_DSN !== undefined) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    environment: process.env.REACT_APP_ENVIRONMENT,
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [],
    // Session Replay
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
  })
}

let meta = {}
if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
  meta = {
    meta: {
      name: {
        robots: 'noindex, nofollow',
      },
    },
  }
}

function App() {
  useCookieScript('//cdn.cookie-script.com/s/a5a63c2bc6b289d948f7edf356e80e14.js', {
    position: 'head-top',
  })
  return (
    <DocumentMeta {...meta}>
      <BrowserRouter basename="/">
        <Router history={history}>
          <AlertProvider>
            <AuthProvider>
              <DashboardTabProvider>
                <NotificationsAndMessagesProvider>
                  <Alerts />
                  <Header />
                  <Switch>
                    <Route exact path={URL_PATHS.HOME} component={HomePage} />
                    <Route exact path={URL_PATHS.ABOUT} component={About} />
                    <Route exact path={URL_PATHS.CONTACT} component={Contact} />
                    <Route exact path={URL_PATHS.SIGNUP} component={SignUpClient} />
                    <Route exact path={URL_PATHS.SIGNUP_CONTRACTOR} component={SignUpContractor} />
                    <Route exact path={URL_PATHS.INFO_AFTER_SIGNUP} component={InfoAfterSignup} />
                    <Route exact path={URL_PATHS.LOGIN} component={Login} />
                    <Route exact path={URL_PATHS.PASSWORD_RESET} component={passwordReset} />
                    <Route exact path={DYNAMIC_URL_PATHS.PasswordUpdate(':token')} component={PasswordUpdate} />
                    <Route
                      exact
                      path={DYNAMIC_URL_PATHS.ContractorProfile(':contractorId')}
                      component={ContractorProfilePage}
                    />
                    <PrivateRoute exact path={URL_PATHS.DASHBOARD} component={Dashboard} />
                    <PrivateRoute exact path={URL_PATHS.CHAT} component={Chat} />
                    <Route exact path={URL_PATHS.BLOG} component={Blog} />
                    <Route exact path={URL_PATHS.BLOG_DETAIL} component={BlogDetails} />
                    <Route exact path={URL_PATHS.FUCHA_LISTING} component={FuchaListing} />
                    <Route exact path={DYNAMIC_URL_PATHS.FuchaDetail(':fuchaId')} component={FuchaDetails} />
                    <Route exact path={URL_PATHS.HOW_IT_WORKS} component={HowItWork} />
                    <Route exact path={URL_PATHS.FAQ} component={Faq} />
                    <Route exact path={URL_PATHS.PRICES} component={PricesPage} />
                    <Route exact path={URL_PATHS.TERMS_AND_CONDITIONS} component={TermsAndConditions} />
                    <Route exact path={URL_PATHS.PRIVACY_POLICY} component={PrivacyPolicyContent} />
                    <Route exact path={URL_PATHS.NOT_FOUND} component={ErrorPage} />
                    <Route exact path={'*'} component={ErrorPage} />
                  </Switch>
                  <Footer />
                  <BottomNav />
                </NotificationsAndMessagesProvider>
              </DashboardTabProvider>
            </AuthProvider>
          </AlertProvider>
        </Router>
      </BrowserRouter>
    </DocumentMeta>
  )
}

export default App
