import React from 'react'
import ConfirmModal from './ConfirmModal'
import AccountIcon from '../../assets/images/icons/account.svg'
import { fixedValue } from '../../_helpers'
import Skeleton from 'react-loading-skeleton'

const ReviewItem = ({ label, value, loading }) => (
  <div className="rating-box">
    <div className="text-part">
      {loading ? (
        <p>
          <Skeleton width={100} />
        </p>
      ) : (
        <p>{label}</p>
      )}
    </div>
    <div className="stars-part">
      <i className="bi bi-star-fill"></i>
      {loading ? (
        <p>
          <Skeleton width={50} />
        </p>
      ) : (
        <p>{value}</p>
      )}
    </div>
  </div>
)

const UserReviewsSummaryModal = ({
  userId,
  userName,
  userImage,
  reviewsSummary,
  handleClose,
  loading,
  showModal,
  isContractor = true,
}) => {
  const avatar = userImage || AccountIcon
  return (
    <ConfirmModal
      header="Podsumowanie recenzji"
      showModal={showModal}
      handleClose={handleClose}
      size="lg"
      className="contractor-review-modal contractor-summary-modal"
      hasFooter={false}
    >
      <img src={process.env.PUBLIC_URL + '/images/bg/client-bg.png'} className="review-bg" alt="" />
      <div className="row align-items-center flex-column review-container">
        <div className="col-lg-12 w-100">
          <div className="d-flex justify-content-center align-items-center h-100 w-100">
            <div className="image-container">
              {loading ? (
                <Skeleton circle width={110} height={110} />
              ) : (
                <img id={`user-image-${userId}`} src={avatar} alt="Admin" className="rounded-circle" width="110" />
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-12 w-100">
          <div className="d-flex flex-column align-items-center h-100 user-name-box">
            {loading ? (
              <p>
                <Skeleton width={100} />
              </p>
            ) : (
              <p>{userName}</p>
            )}
          </div>
        </div>

        <div className="col-lg-12 w-100" style={{ paddingBottom: '20px' }}>
          <div className="d-flex flex-column align-items-center h-100" style={{ margin: 'auto' }}>
            <ReviewItem loading={loading} label="Liczba recenzji" value={reviewsSummary.total_reviews} />
            <ReviewItem loading={loading} label="Ogólna ocena" value={fixedValue(reviewsSummary.average_score, 2)} />
            <p className="middle-text">Średna z poszczególnych typów recenzji:</p>
            {isContractor ? (
              <>
                <ReviewItem loading={loading} label="Koszt wykonania" value={fixedValue(reviewsSummary.price, 2)} />
                <ReviewItem
                  loading={loading}
                  label="Czas wykonania"
                  value={fixedValue(reviewsSummary.completion_time, 2)}
                />
                <ReviewItem loading={loading} label="Jakość wykonania" value={fixedValue(reviewsSummary.quality, 2)} />
                <ReviewItem
                  loading={loading}
                  label="Kontakt z fachowcem"
                  value={fixedValue(reviewsSummary.contact, 2)}
                />
              </>
            ) : (
              <>
                <ReviewItem
                  loading={loading}
                  label="Kontakt z klientem"
                  value={fixedValue(reviewsSummary.contact, 2)}
                />
                <ReviewItem
                  loading={loading}
                  label="Płatność za usługę"
                  value={fixedValue(reviewsSummary.payment, 2)}
                />
                <ReviewItem
                  loading={loading}
                  label="Zgodność opisu fuchy"
                  value={fixedValue(reviewsSummary.descriptionAccuracy, 2)}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </ConfirmModal>
  )
}

export default UserReviewsSummaryModal
