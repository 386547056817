import React from 'react'
import { Link } from 'react-router-dom'
import { URL_PATHS } from '../../../../_constants/urls'
import { scrollToTop } from '../../../../_helpers'

const RegisterClientAccountCard = () => {
  return (
    <div className="d-flex align-items-stretch">
      <div className="card side-card mb-3">
        <div className="card-body">
          <div className="sidebar-widget-title">
            <h4>A może masz fuchę do zlecenia?</h4>
            <span />
          </div>
          <div className="details-container">
            <div className="product-details-right  wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
              <div className="form-title no-signed-view-title">
                <h5>Chcesz wystawić fuchę?</h5>
                <p className="para">
                  Na <span className="logo-paragraph ">mamfuche.pl</span> znajdziesz sprawdzonych fachowców! Zarejestruj
                  się i zacznij swój wymarzony remont!
                </p>
              </div>
              <div className="eg-btn btn--primary p-2 join-btn">
                <Link to={URL_PATHS.SIGNUP} onClick={() => scrollToTop()}>
                  Wystaw Fuchę
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegisterClientAccountCard
