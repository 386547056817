import { z } from 'zod'
import { NIP_REGEX } from '../../../../../_constants'

// Simple NIP validation (only numeric and 10 digits long)
const nipSchema = z.string().regex(NIP_REGEX, 'NIP musi składać się z dokładnie 10 cyfr')

export const CreateContractorProfile = z.object({
  businessType: z.enum(['brak', 'działalność jednoosobowa', 'firma']).optional().nullable(),
  bioContractor: z.string().max(2500, 'Max 2500 znaków').optional().nullable(),
  companyName: z
    .string()
    .optional()
    .nullable()
    .refine(
      (val) => {
        if (!val) return true
        return val.length >= 3 && val.length <= 100
      },
      {
        message: 'Minimalna liczba znaków to 3, a maksymalna to 100',
      }
    ),
  companyTaxId: nipSchema.or(z.literal('')),
})
