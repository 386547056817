import React from 'react'
import { Link } from 'react-router-dom'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { DYNAMIC_URL_PATHS } from '../../../../_constants'
import { scrollToTop } from '../../../../_helpers'
import AccountIcon from '../../../../assets/images/icons/account.svg'
import { renderStarRating } from '../../../../_helpers'
import { BID_RATE_TYPE } from '../../../../_constants/values'

const BigScreenBidItem = (props) => {
  const {
    acceptedBid,
    bid,
    disabledFuchaAction,
    fucha,
    isOwner,
    handleOpenContractorReviewsSummaryModal,
    handleGetContractorReviewsSummary,
    handleChatStart,
    setBidToAccept,
    setShowModal,
    setShowReviewModal,
    setBidIdToDelete,
  } = props
  const contractor = bid.contractor
  const isAccepted = bid.id === acceptedBid?.id

  return (
    <>
      <div key={bid.id} className="offer-list-item-container d-lg-flex d-none">
        <div style={{ maxWidth: '80px' }} className="offer-image-container">
          <div className="offer-image-box">
            <Link to={DYNAMIC_URL_PATHS.ContractorProfile(bid.contractor.id)} onClick={() => scrollToTop()}>
              <OverlayTrigger
                key={`contractor-img-${bid.id}`}
                placement="top"
                overlay={<Tooltip id={`contractor-img-${bid.id}`}>Kliknij żeby przejść do profilu fachowca</Tooltip>}
              >
                <img
                  id={`contractor-img-${bid.id}`}
                  src={contractor.image ? contractor.image : AccountIcon}
                  alt="Admin"
                  className="rounded-circle"
                  width="50"
                />
              </OverlayTrigger>
            </Link>
          </div>
        </div>

        {contractor.enable_display_company_name && contractor.company_name ? (
          <div style={{ maxWidth: '190px' }} className="offer-list-item">
            <Link to={DYNAMIC_URL_PATHS.ContractorProfile(bid.contractor.id)} onClick={() => scrollToTop()}>
              <OverlayTrigger
                key={`contractor-name-${bid.id}`}
                placement="top"
                overlay={<Tooltip id={`contractor-name-${bid.id}`}>Kliknij żeby przejść do profilu fachowca</Tooltip>}
              >
                <p id={`contractor-name-${bid.id}`} className="para name">
                  {contractor.company_name}
                </p>
              </OverlayTrigger>
            </Link>
          </div>
        ) : (
          <div style={{ maxWidth: '135px' }} className="offer-list-item">
            <Link to={DYNAMIC_URL_PATHS.ContractorProfile(bid.contractor.id)} onClick={() => scrollToTop()}>
              <OverlayTrigger
                key={`contractor-name-${bid.id}`}
                placement="top"
                overlay={<Tooltip id={`contractor-name-${bid.id}`}>Kliknij żeby przejść do profilu fachowca</Tooltip>}
              >
                <p id={`contractor-name-${bid.id}`} className="para name">
                  {contractor.name}
                </p>
              </OverlayTrigger>
            </Link>
          </div>
        )}

        <div style={{ maxWidth: '150px' }} className="offer-list-item">
          <div
            style={{ cursor: bid.contractor.average_score > 0 ? 'pointer' : 'auto' }}
            className="offer-review-rate"
            onClick={() => {
              if (bid.contractor.average_score > 0) {
                handleOpenContractorReviewsSummaryModal(bid)
                handleGetContractorReviewsSummary(bid.contractor.id)
              }
            }}
          >
            {renderStarRating(bid.contractor.average_score)}
            <span>({bid.contractor.average_score ? bid.contractor.average_score.toFixed(2) : '0'})</span>
          </div>
        </div>

        <div style={{ maxWidth: '50px', paddingLeft: '40px', paddingRight: '35px' }} className="offer-list-item">
          <div
            className={`icon-container offer-icon-container ${contractor.is_email_verified ? 'verified' : 'not-verified'}`}
          >
            <OverlayTrigger
              key={`${bid.id}-confirm-offer`}
              placement="top"
              overlay={
                <Tooltip id={`${bid.id}-envelope`}>
                  {contractor.is_email_verified ? 'Zweryfikowany adres email' : 'Niezweryfikowany adres email'}
                </Tooltip>
              }
            >
              <div id={`${bid.id}-envelope`} className="icon">
                <i className="bi bi-envelope"></i>
              </div>
            </OverlayTrigger>
          </div>
        </div>

        <div style={{ maxWidth: '50px' }} className="offer-list-item">
          <div
            className={`icon-container offer-icon-container ${contractor.is_phone_verified ? 'verified' : 'not-verified'}`}
          >
            <OverlayTrigger
              key={`${bid.id}-confirm-offer`}
              placement="top"
              overlay={
                <Tooltip id={`${bid.id}-phone`}>
                  {contractor.is_phone_verified ? 'Zweryfikowany numer telefonu' : 'Niezweryfikowany numer telefonu'}
                </Tooltip>
              }
            >
              <div id={`${bid.id}-phone`} className="icon">
                <i className="bi bi-telephone"></i>
              </div>
            </OverlayTrigger>
          </div>
        </div>

        <div style={{ maxWidth: '200px' }} className="offer-list-item flex-column">
          <p className="para">
            Kwota: {bid.bid} zł{' '}
            {bid.rate_type === BID_RATE_TYPE.hourly
              ? ' /h'
              : bid.rate_type === BID_RATE_TYPE.meter
                ? ' /mb'
                : bid.rate_type === BID_RATE_TYPE.sqm
                  ? ' /m2'
                  : ''}
          </p>
          {bid.need_more_info && (
            <p style={{ fontSize: '10px', color: '#f9395f', fontWeight: 'normal' }} className="para">
              Potrzebuję więcej informacji do dokładniejszej wyceny
            </p>
          )}
        </div>
        <div style={{ maxWidth: '220px' }} className="offer-list-item">
          <p className="para">{bid.timesince} temu</p>
        </div>

        {isOwner ? (
          <>
            {!acceptedBid && (
              <div style={{ maxWidth: '120px' }} className="offer-list-item">
                <OverlayTrigger
                  key={`${bid.id}-confirm-offer`}
                  placement="top"
                  overlay={
                    <Tooltip id={`${bid.id}-confirm-offer`}>
                      {disabledFuchaAction ? 'Nie możesz zatwierdzić fuchy która jest nieaktywna' : 'Zatwierdź wycenę'}
                    </Tooltip>
                  }
                >
                  <button
                    id={`${bid.id}-confirm-offer`}
                    className={`eg-btn btn--primary btn--sm confirm ${disabledFuchaAction ? 'expired-fucha-btn' : ''}`}
                    onClick={() => {
                      setBidToAccept(bid)
                      setShowModal(true)
                    }}
                    disabled={disabledFuchaAction}
                  >
                    Zatwierdź
                  </button>
                </OverlayTrigger>
              </div>
            )}

            <div style={{ maxWidth: '70px' }} className="offer-list-item">
              <OverlayTrigger
                key={`${bid.id}-message`}
                placement="top"
                overlay={
                  <Tooltip id={`${bid.id}-message`}>
                    {disabledFuchaAction
                      ? 'Nie możesz napisać do fachowca. Fucha nie jest aktywna'
                      : 'Napisz wiadomość do fachowca'}
                  </Tooltip>
                }
              >
                <button
                  disabled={disabledFuchaAction}
                  id={`${bid.id}-message`}
                  className={`eg-btn btn--primary btn--sm ${disabledFuchaAction ? 'expired-fucha-btn' : ''}`}
                  onClick={() => handleChatStart(bid.contractor.id)}
                >
                  <i className="bi bi-chat-left-dots"></i>
                </button>
              </OverlayTrigger>
            </div>

            {isAccepted ? (
              <div style={{ maxWidth: '70px' }} className="offer-list-item">
                <OverlayTrigger
                  key={`${bid.id}-contractor-review`}
                  placement="top"
                  overlay={
                    fucha.has_review ? (
                      <Tooltip id={`${bid.id}-contractor-review`}>Już wystawiłeś recenzję dla tego fachowca</Tooltip>
                    ) : (
                      <Tooltip id={`${bid.id}-contractor-review`}>Oceń fachowca</Tooltip>
                    )
                  }
                >
                  <button
                    onClick={() => {
                      setShowReviewModal(true)
                    }}
                    id={`${bid.id}-contractor-review`}
                    className={`${fucha.has_review ? 'add-review-button' : 'btn--primary'} eg-btn btn--sm`}
                    disabled={fucha.has_review}
                  >
                    <i className="bi bi-star"></i>
                  </button>
                </OverlayTrigger>
              </div>
            ) : null}
          </>
        ) : (
          !bid.is_deleted && (
            <>
              <OverlayTrigger
                key={`#${bid.id}-delete`}
                placement="top"
                overlay={<Tooltip id={`#${bid.id}-delete`}>Usuń swoją wycenę</Tooltip>}
              >
                <button
                  id={`${bid.id}-delete`}
                  className={`eg-btn btn--primary btn--sm`}
                  onClick={() => {
                    setBidIdToDelete(bid.id)
                    setShowModal(true)
                  }}
                >
                  <i className="bi bi-x-square"></i>
                </button>
              </OverlayTrigger>
            </>
          )
        )}
      </div>
    </>
  )
}

export default BigScreenBidItem
