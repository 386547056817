import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import AccountIcon from '../../../assets/images/icons/account.svg'
import ImageGallery from 'react-image-gallery'
import { useContractorActions } from '../../../_actions'
import { shouldDisplayServerSideError, renderStarRating } from '../../../_helpers'
import { useAlerts } from '../../../context/AlertContext'
import Breadcrumb from '../../common/Breadcrumb'
import UserReviewsSummaryModal from '../../common/UserReviewsSummaryModal'
import { ACCOUNT_STATUS } from '../../../_constants/user'
import { history } from '../../../_helpers'
import { URL_PATHS } from '../../../_constants'

const ContractorProfilePage = () => {
  const { getGallery, getPublicProfile, getContractorReviewsSummary } = useContractorActions()
  const { contractorId } = useParams()
  const { addAlert } = useAlerts()
  const [loading, setLoading] = useState(true)
  const [contractor, setContractor] = useState(null)
  const [galleryImages, setGalleryImages] = useState([])
  const [contractorReviewsSummary, setContractorReviewsSummary] = useState([])
  const [showContractorReviewsSummaryModal, setShowContractorReviewsSummaryModal] = useState(false)
  const isActiveContractor = contractor?.account_status === ACCOUNT_STATUS.ACTIVE

  useEffect(() => {
    Promise.all([
      getGallery(contractorId)
        .then((resp) => {
          const images = resp.map((image) => ({
            original: `${image.url}`,
            thumbnail: `${image.thumbnail_url}`,
          }))
          setGalleryImages(images)
        })
        .catch((err) => {
          addAlert('Nie udało się pobrać zdjęć z serwera.', 'error')
        }),
      getPublicProfile(contractorId)
        .then((resp) => {
          setContractor(resp)
        })
        .catch((err) => {
          console.log(err)
          if (err?.response?.status === 404 || err === 'Unprocessable Entity') {
            history.push(URL_PATHS.NOT_FOUND)
          } else {
            addAlert('Nie udało się pobrać szczegółów fachowca. Spróbuj ponownie później.', 'error')
          }
        }),
      getContractorReviewsSummary(contractorId)
        .then((data) => {
          setContractorReviewsSummary(data)
        })
        .catch((err) => {
          if (shouldDisplayServerSideError(err)) {
            addAlert(err.response.data.detail, 'error')
          } else {
            addAlert('Wystąpił błąd podczas pobierania danych o recenzjach', 'error')
          }
        }),
    ]).finally(() => {
      setLoading(false)
    })
  }, [contractorId])

  const handleOpenContractorReviewsSummaryModal = () => {
    setShowContractorReviewsSummaryModal(true)
  }
  const handleCloseContractorReviewsSummaryModal = () => {
    setShowContractorReviewsSummaryModal(false)
  }

  const renderSkeleton = () => (
    <div className="pt-120 pb-120 contractor-profile-container">
      <div className="container">
        <div className="main-body">
          <div className="row">
            <div className="row">
              {/* USER INFORMATION AREA */}
              <div className="col-md-12 mb-3">
                <div className="card personal-info-container">
                  <div className="card-body">
                    <div className="d-flex flex-column align-items-center text-center w-100">
                      <div className="image-container">
                        <div className="image bg-light">
                          <Skeleton circle />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="info-container">
                    <div className="d-flex flex-column">
                      <div className="contractor-info-container">
                        <h4>
                          <Skeleton height={25} width={200} />
                        </h4>
                        <div className="review-rate">
                          <Skeleton width="30%" />
                        </div>
                      </div>
                      <div className="contractor-info-icons">
                        <Skeleton width="40%" />
                        <Skeleton width="40%" />
                        <Skeleton width="40%" />
                      </div>
                    </div>
                    <div className="blog-widget-item">
                      <Skeleton width={200} height={40} />
                    </div>
                  </div>
                  <div className="contractor-personal-info-container">
                    <Skeleton width="60%" />
                    <Skeleton width="60%" />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3 contractor-details-container">
              {/* DESCRIPTION */}
              <div className="description-box">
                <div className="card single-feature summary-container hover-border1 wow fadeInDown">
                  <div className="card-body">
                    <div className="summary-content">
                      <h4>
                        <Skeleton height={40} width={200} />
                      </h4>
                      <Skeleton height={200} />
                    </div>
                  </div>
                </div>
              </div>

              {/* WORK AREA */}
              <div className="work-area-box">
                <div className="card single-feature work-area-card hover-border1 wow fadeInDown">
                  <div className="card-body">
                    <div className="summary-content">
                      <h4>
                        <Skeleton height={40} width={200} />
                      </h4>
                      <div className="tags">
                        <Skeleton count={2} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* TYPES OF WORK */}
              <div className="types-work-box">
                <div className="card single-feature work-area-card hover-border1 wow fadeInDown">
                  <div className="card-body">
                    <div className="summary-content">
                      <h4>
                        <Skeleton height={40} width={200} />
                      </h4>
                      <div className="tags">
                        <Skeleton count={2} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {/* GALLERY */}
              <div className="col-xl-12 mb-3">
                <div className="card">
                  <div className="card-body gallery-container">
                    <h4>
                      <Skeleton height={40} width={200} />
                    </h4>
                    <Skeleton height={200} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <>
      <Breadcrumb pageName="Profil Fachowca" pageTitle={`Oto ${contractor ? contractor.first_name : ''}!`} />
      {!contractor ? (
        renderSkeleton()
      ) : (
        <>
          <div
            className={`pt-120 pb-120 contractor-profile-container ${!isActiveContractor ? 'inactive-account' : ''}`}
          >
            <div className="container">
              <div className="main-body">
                <div className="row">
                  <div className="row">
                    {/* USER INFORMATION AREA */}
                    <div className="col-md-12 mb-3">
                      <div className="card personal-info-container">
                        <div className="card-body">
                          <div className="d-flex flex-column align-items-center text-center w-100">
                            <div className="image-container">
                              <div className="image bg-light">
                                <img
                                  src={contractor.profile_image ? `${contractor.profile_image}` : AccountIcon}
                                  alt="Admin"
                                  className="rounded-circle"
                                  width="150"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="info-container">
                          <div className="d-flex flex-column">
                            {contractor?.profile?.enable_display_company_name ? (
                              <div className="contractor-info-container flex-column justify-content-start">
                                <div style={{ width: '100%', justifyContent: 'flex-start', marginRight: '0', marginBottom: '10px' }} className="sidebar-widget-title">
                                  <h4 style={{ textTransform: 'none' }}>{contractor?.profile?.company_name}</h4>
                                </div>
                                <div
                                  style={{
                                    width: '100%',
                                    cursor: contractorReviewsSummary.average_score ? 'pointer' : 'auto',
                                  }}
                                  className="review-rate justify-content-start"
                                  onClick={() => {
                                    if (contractorReviewsSummary.average_score) {
                                      handleOpenContractorReviewsSummaryModal()
                                    }
                                  }}
                                >
                                  {renderStarRating(contractorReviewsSummary.average_score)}
                                  <span>
                                    (
                                    {contractorReviewsSummary.average_score
                                      ? contractorReviewsSummary.average_score.toFixed(2)
                                      : '0'}
                                    )
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div className="contractor-info-container">
                                <div className="sidebar-widget-title">
                                  <h4>{contractor.first_name}</h4>
                                </div>
                                <div
                                  style={{ cursor: contractorReviewsSummary.average_score ? 'pointer' : 'auto' }}
                                  className="review-rate"
                                  onClick={() => {
                                    if (contractorReviewsSummary.average_score) {
                                      handleOpenContractorReviewsSummaryModal()
                                    }
                                  }}
                                >
                                  {renderStarRating(contractorReviewsSummary.average_score)}
                                  <span>
                                    (
                                    {contractorReviewsSummary.average_score
                                      ? contractorReviewsSummary.average_score.toFixed(2)
                                      : '0'}
                                    )
                                  </span>
                                </div>
                              </div>
                            )}
                            <div className="contractor-info-icons">
                              {contractor?.profile?.company_name && (
                                <div className="icon-container">
                                  <div className="icon">
                                    <i style={{ cursor: 'default' }} className="fas fa-toolbox"></i>
                                  </div>
                                  <p>{contractor?.profile?.company_name}</p>
                                </div>
                              )}

                              {contractor?.profile?.company_tax_id &&
                                contractor?.profile?.company_tax_id !== '0000000000' && (
                                  <div className="icon-container">
                                    <div className="icon">
                                      <i style={{ cursor: 'default' }} className="bi bi-briefcase"></i>
                                    </div>
                                    <p>NIP: {contractor?.profile?.company_tax_id}</p>
                                  </div>
                                )}
                              <div className="icon-container">
                                <div className="icon">
                                  <i style={{ cursor: 'default' }} className="bi bi-calendar-check"></i>
                                </div>
                                <p>Z mamfuche.pl od: {new Date(contractor.created_at).toLocaleDateString('pl-PL')}</p>
                              </div>
                              {contractor.address?.city && (
                                <div className="icon-container">
                                  <div className="icon">
                                    <i style={{ cursor: 'default' }} className="fas fa-map-marker-alt"></i>
                                  </div>
                                  <p>{contractor.address?.city}</p>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="blog-widget-item wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".8s">
                            <div className="tag-area">
                              <div className="blog-widget-body">
                                <ul className="sidebar-social-list gap-4">
                                  <li>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={<Tooltip id="tooltip-facebook">Facebook</Tooltip>}
                                    >
                                      <a
                                        href={contractor?.profile?.facebook_url || '#'}
                                        className={contractor?.profile?.facebook_url ? 'is-link' : 'no-link'}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={(e) => {
                                          if (!contractor?.profile?.facebook_url) e.preventDefault()
                                        }}
                                      >
                                        <i className="bx bxl-facebook" />
                                      </a>
                                    </OverlayTrigger>
                                  </li>
                                  <li>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={<Tooltip id="tooltip-tiktok">TikTok</Tooltip>}
                                    >
                                      <a
                                        href={contractor?.profile?.tiktok_url || '#'}
                                        className={contractor?.profile?.tiktok_url ? 'is-link' : 'no-link'}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={(e) => {
                                          if (!contractor?.profile?.tiktok_url) e.preventDefault()
                                        }}
                                      >
                                        {contractor?.profile?.tiktok_url ? (
                                          <img
                                            className="tiktok"
                                            src={process.env.PUBLIC_URL + '/images/icons/tiktok-red.svg'}
                                            alt="icon"
                                          />
                                        ) : (
                                          <img
                                            className="tiktok"
                                            src={process.env.PUBLIC_URL + '/images/icons/tiktok-grey.svg'}
                                            alt="icon"
                                          />
                                        )}
                                      </a>
                                    </OverlayTrigger>
                                  </li>
                                  <li>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={<Tooltip id="tooltip-instagram">Instagram</Tooltip>}
                                    >
                                      <a
                                        href={contractor?.profile?.instagram_url || '#'}
                                        className={contractor?.profile?.instagram_url ? 'is-link' : 'no-link'}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={(e) => {
                                          if (!contractor?.profile?.instagram_url) e.preventDefault()
                                        }}
                                      >
                                        {contractor?.profile?.instagram_url ? (
                                          <img
                                            className="instagram"
                                            src={process.env.PUBLIC_URL + '/images/icons/instagram-red.svg'}
                                            alt="icon"
                                          />
                                        ) : (
                                          <img
                                            className="instagram"
                                            src={process.env.PUBLIC_URL + '/images/icons/instagram-grey.svg'}
                                            alt="icon"
                                          />
                                        )}
                                      </a>
                                    </OverlayTrigger>
                                  </li>
                                  <li>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={<Tooltip id="tooltip-website">Strona internetowa fachowca</Tooltip>}
                                    >
                                      <a
                                        href={contractor?.profile?.website_url || '#'}
                                        className={contractor?.profile?.website_url ? 'is-link' : 'no-link'}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={(e) => {
                                          if (!contractor?.profile?.website_url) e.preventDefault()
                                        }}
                                      >
                                        {contractor?.profile?.website_url ? (
                                          <img
                                            className="globe"
                                            src={process.env.PUBLIC_URL + '/images/icons/globe-red.svg'}
                                            alt="icon"
                                          />
                                        ) : (
                                          <img
                                            className="globe"
                                            src={process.env.PUBLIC_URL + '/images/icons/globe-grey.svg'}
                                            alt="icon"
                                          />
                                        )}
                                      </a>
                                    </OverlayTrigger>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="contractor-personal-info-container">
                          {isActiveContractor && contractor.email && (
                            <div className="icon-container">
                              <div className="icon">
                                <i style={{ cursor: 'default' }} className="bx bx-envelope" />
                              </div>
                              <p className="text-secondary">{contractor.email}</p>
                            </div>
                          )}
                          {isActiveContractor && contractor.phone_number && (
                            <div className="icon-container">
                              <div className="icon">
                                <i style={{ cursor: 'default' }} className="bx bx-phone-call" />
                              </div>
                              <p className="text-muted font-size-sm">{contractor.phone_number}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="mb-3 contractor-details-container">
                      {/* DESCRIPTION */}
                      <div className="description-box">
                        <div
                          className="card single-feature summary-container hover-border1 wow fadeInDown"
                          data-wow-duration="1.5s"
                          data-wow-delay=".2s"
                        >
                          <div className="card-body">
                            <div className="summary-content">
                              <div className="sidebar-widget-title">
                                <h4>Poznaj mnie lepiej</h4>
                                <span />
                              </div>
                              {contractor?.profile?.description ? (
                                <div className="description-container">
                                  <div className="description">
                                    <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} className="para">
                                      {contractor?.profile?.description}
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                <div className="description-container no-description-container">
                                  <div className="description">
                                    <p className="para">Opis nie został jeszcze dodany...</p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="work-area-box">
                        {/* WORK AREA */}
                        <div
                          className="card single-feature work-area-card hover-border1 wow fadeInDown"
                          data-wow-duration="1.5s"
                          data-wow-delay=".2s"
                        >
                          <div className="card-body">
                            <div className="summary-content">
                              <div className="sidebar-widget-title">
                                <h4>Teren na którym pracuję</h4>
                                <span />
                              </div>
                              <div
                                className={`tags ${contractor?.profile?.work_area?.is_country_wide || contractor?.profile?.work_area?.provinces?.length > 0 || contractor?.profile?.work_area?.counties?.length > 0 ? 'has-work-area' : 'empty'}`}
                              >
                                {contractor.profile === null && (
                                  <p className="para">
                                    <b>Teren nie został jeszcze dodany...</b>
                                  </p>
                                )}
                                {contractor?.profile?.work_area?.is_country_wide ? (
                                  <span className="tag tag-province">CAŁA POLSKA</span>
                                ) : (
                                  contractor?.profile?.work_area?.provinces && (
                                    <>
                                      {contractor?.profile?.work_area?.provinces.map((province) => (
                                        <span key={province.province} className="tag tag-province">
                                          {province.is_province_wide ? `CAŁE ${province.province}` : province.province}
                                        </span>
                                      ))}
                                      {contractor?.profile?.work_area?.provinces.flatMap((province) =>
                                        province.counties.map((county) => (
                                          <span key={county} className="tag tag-county">
                                            {county}
                                          </span>
                                        ))
                                      )}
                                    </>
                                  )
                                )}
                                {contractor?.profile?.work_area?.provinces?.length === 0 &&
                                  contractor?.profile?.work_area?.is_country_wide === false && (
                                    <p className="para">
                                      <b>Teren nie został jeszcze dodany...</b>
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="types-work-box">
                        {/* TYPES OF WORK */}
                        <div
                          className="card single-feature work-area-card hover-border1 wow fadeInDown"
                          data-wow-duration="1.5s"
                          data-wow-delay=".2s"
                        >
                          <div className="card-body">
                            <div className="summary-content">
                              <div className="sidebar-widget-title">
                                <h4>Tym się zajmuję</h4>
                                <span />
                              </div>
                              <div
                                className={`tags ${contractor?.profile?.types_of_work.length > 0 ? 'has-types-of-work' : 'empty'}`}
                              >
                                {contractor.profile === null && (
                                  <p className="para">
                                    <b>Typy pracy nie zostały jeszcze dodane...</b>
                                  </p>
                                )}
                                {contractor?.profile?.types_of_work &&
                                  contractor.profile.types_of_work.map((type) => (
                                    <span key={type.id} className="tag tag-county">
                                      {type.name}
                                    </span>
                                  ))}
                                {contractor?.profile?.types_of_work.length === 0 && (
                                  <p className="para">
                                    <b>Typy pracy nie zostały jeszcze dodane...</b>
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    {/* GALLERY */}
                    <div className="col-xl-12 mb-3">
                      <div
                        className={`card contractor-gallery-card ${galleryImages.length === 0 ? 'empty-gallery-card' : ''}`}
                      >
                        <div className="card-body gallery-container ">
                          <div className="sidebar-widget-title">
                            <h4>Zdjęcia z wykonanych fuch</h4>
                            <span />
                          </div>
                          {galleryImages.length > 0 ? (
                            <ImageGallery
                              additionalClass="contractor-gallery-container"
                              items={galleryImages}
                              showPlayButton={false}
                            />
                          ) : (
                            <p className="para">
                              <b>Zdjęcia z wykonanych prac nie zostały jeszcze dodane...</b>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <UserReviewsSummaryModal
            handleClose={handleCloseContractorReviewsSummaryModal}
            loading={loading}
            reviewsSummary={contractorReviewsSummary}
            showModal={showContractorReviewsSummaryModal}
            userId={contractor.id}
            userImage={contractor.profile_image_thumbnail}
            userName={
              contractor?.profile?.enable_display_company_name ? contractor.profile.company_name : contractor.first_name
            }
          />
        </>
      )}
    </>
  )
}

export default ContractorProfilePage
